import React, {Component} from "react";
import './tshirtPattern.css';
import {Coordinate, LineStyle, SketchResult, SketchShape, TextPlacement} from "../model/shapes";
import {calculateBezierControlPoint, calculateBezierCurveLength, fromRadians, toRadians} from "../services/mathsUtils";
import {
    createBezierCurveFromCoordinates, createCurve,
    createLine,
    createRectangle,
    createText,
    mirrorShapes,
    TEXT_SIZE
} from "../services/shapeGenerator";
import Form, {FormFieldType} from "./form";
import ShapeRenderer from "./shapeRenderer";
import PrintForm from "./printForm";
import PresentationForm from "./presentationForm";
import {
    getCurrentSavedSettings, getDefaultPantsMeasurements,
    getDefaultPrintSettings, getDefaultTshirtMeasurements, updatePantsMeasurementSettings,
    updatePrintSettings,
    updateTshirtMeasurementSettings
} from "../services/settings";
import {PantsMeasurement, TshirtMeasurements} from "../model/settings";

interface FormValues {
    seamAllowance: string,
    waistband: string,
    seat: string,
    bodyRise: string,
    insideLeg: string,
    bottomHem: string,
    waistbandHeight: string,
    kneeHeight: string,
    seatPointRatio: string,
    legCurveSlope: string,
    kneePadding: string,
    kneeLength: string,
    buttAdjustmentRatio: string,
    backButtCurveAdjustment: string,
}


const FRONT_PANT_LEFT_CURVE_T = 0.45;


const CANVAS_PADDING = TEXT_SIZE * 2;

export default class PantsPattern extends Component<{}, FormValues> {


    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.handleFormUpdate = this.handleFormUpdate.bind(this);
        this.state = this.convertSettingsToState(getCurrentSavedSettings().pants);
    }


    convertStateToSettings(state: FormValues) : PantsMeasurement {
        const values: any = {};
        Object.entries(state).forEach(([k,v]) => {values[k] = parseFloat(v)});
        return values;
    }


    convertSettingsToState(settings: PantsMeasurement) : FormValues {
        const values: any = {};
        Object.entries(settings).forEach(([k,v]) => {values[k] = "" + v});
        return values;
    }


    handleFormUpdate(updatedDetails: any) {
        let newState = {
            ...this.state,
            ...updatedDetails,
        };
        updatePantsMeasurementSettings(this.convertStateToSettings(newState));
        this.setState(newState);
    }


    calculateSlopeLength(topLeftPoint: Coordinate, bottomRightPoint: Coordinate) {
        return Math.sqrt(Math.pow(bottomRightPoint.y - topLeftPoint.y, 2) + Math.pow(bottomRightPoint.x - topLeftPoint.x, 2));
    }

    calculateInvertedSlopeLength(topRightPoint: Coordinate, bottomLeftPoint: Coordinate) {
        return Math.sqrt(Math.pow(bottomLeftPoint.y - topRightPoint.y, 2) + Math.pow(topRightPoint.x - bottomLeftPoint.x, 2));
    }

    calculateSlopeAngle(topLeftPoint: Coordinate, bottomRightPoint: Coordinate) {
        // tan(interior angle) = opposite / adjacent
        // rotation angle = 90 - interior angle
        return 90 - fromRadians(Math.atan((bottomRightPoint.x - topLeftPoint.x) / (bottomRightPoint.y - topLeftPoint.y)));
    }
    calculateInvertedSlopeAngle(topRightPoint: Coordinate, bottomLeftPoint: Coordinate) {
        // tan(interior angle) = opposite / adjacent
        // rotation angle = 90 - interior angle
        return 180 - fromRadians(Math.atan((bottomLeftPoint.y - topRightPoint.y) / (topRightPoint.x - bottomLeftPoint.x)));
    }

    inferCommonPantShapes(canvasWidth: number) : SketchResult {
        const shapes: SketchShape[] = [];
        const points :  Map<number, Coordinate> = new Map();
        let formDetails = getCurrentSavedSettings().pants;
        const canvasHeight = (formDetails.bodyRise + formDetails.seamAllowance + formDetails.insideLeg - formDetails.waistbandHeight) *10;

        shapes.push(createRectangle({x: 0, y: 0}, canvasWidth, canvasHeight + CANVAS_PADDING, 0));


        points.set(0, {x: canvasWidth/2, y: 0}) // waistband
        shapes.push(createLine(points.get(0)!, canvasHeight, 90, LineStyle.GUIDE));
        shapes.push(createText(points.get(0)!, "0"));

        points.set(1, { // body rise
            x: points.get(0)!.x,
            y: points.get(0)!.y + (formDetails.bodyRise + formDetails.seamAllowance - formDetails.waistbandHeight) * 10
        });
        shapes.push(createText(points.get(1)!, "1"));

        points.set(2, { // bottom hem
            x: points.get(1)!.x,
            y: points.get(1)!.y + (formDetails.insideLeg) * 10
        });
        shapes.push(createText(points.get(2)!, "2"));

        points.set(3, { // knee
            x: points.get(2)!.x,
            y: points.get(2)!.y - (formDetails.kneeHeight) * 10
        });
        shapes.push(createText(points.get(3)!, "3"));

        points.set(4, {  // seat
            x: points.get(0)!.x,
            y: points.get(0)!.y+ ((points.get(1)!.y - points.get(0)!.y)*(1-formDetails.seatPointRatio))
        });
        shapes.push(createText(points.get(4)!, "4"));


        points.set(5, { // protrouding on left at body rise line, raised up to top
            x: points.get(1)!.x - (((formDetails.seat) /8) - formDetails.legCurveSlope)*10,
            y: points.get(1)!.y
        });
        shapes.push(createText(points.get(5)!, "5"));

        shapes.push(createLine(points.get(5)!, points.get(1)!.x - points.get(5)!.x, 0, LineStyle.GUIDE));
        shapes.push(createLine(points.get(5)!, points.get(5)!.y - points.get(0)!.y, 270, LineStyle.GUIDE));

        points.set(6, { // mirror on seat line
            x: points.get(1)!.x + (((formDetails.seat) /8) + formDetails.legCurveSlope)*10,
            y: points.get(4)!.y
        });
        shapes.push(createText(points.get(6)!, "6"));

        points.set(7, { // protrouding on left at body rise line, and an extra bit for curve
            x: points.get(1)!.x - ((((formDetails.seat) /8) - formDetails.legCurveSlope)*10)*1.5,
            y: points.get(1)!.y
        });
        shapes.push(createText(points.get(7)!, "7"));

        points.set(8, { // start of left curve up top, pushed a bit inwards
            x: points.get(1)!.x - (((formDetails.seat) /8) - formDetails.legCurveSlope + formDetails.legCurveSlope)*10,
            y: points.get(0)!.y
        });
        shapes.push(createText(points.get(8)!, "8"));

        points.set(9, { // mirror of point6, point on curve
            x: points.get(0)!.x - (points.get(6)!.x - points.get(0)!.x),
            y: points.get(6)!.y
        });
        shapes.push(createText(points.get(9)!, "9"));

        shapes.push(createLine(points.get(8)!, this.calculateInvertedSlopeLength(points.get(8)!, points.get(9)!), this.calculateInvertedSlopeAngle(points.get(8)!, points.get(9)!), LineStyle.GUIDE));
        shapes.push(createBezierCurveFromCoordinates(points.get(8)!, points.get(9)!, points.get(7)!, 0.5));
        // shapes.push(createCurve(points.get(9)!, points.get(7)!, points.get(5)!));


        points.set(10, { // mirroring the waist point
            x: points.get(8)!.x + (formDetails.waistband / 4 + formDetails.legCurveSlope * 2)*10,
            y: points.get(0)!.y
        });
        shapes.push(createText(points.get(10)!, "10"));
        shapes.push(createLine(points.get(8)!, points.get(10)!.x - points.get(8)!.x, 0));

        points.set(11, { // bottom hem on left side
            x: points.get(2)!.x - (formDetails.bottomHem / 4)*10,
            y: points.get(2)!.y
        });
        shapes.push(createText(points.get(11)!, "11"));

        points.set(12, { // bottom hem on right side
            x: points.get(2)!.x + (formDetails.bottomHem / 4)*10,
            y: points.get(2)!.y
        });
        shapes.push(createText(points.get(12)!, "12"));

        shapes.push(createLine(points.get(11)!, points.get(12)!.x - points.get(11)!.x, 0));

        points.set(13, { // knee on left side
            x: points.get(3)!.x - (formDetails.bottomHem / 4 + formDetails.kneePadding)*10,
            y: points.get(3)!.y
        });
        shapes.push(createText(points.get(13)!, "13"));

        points.set(14, { // knee on right side
            x: points.get(3)!.x + (formDetails.bottomHem / 4 + formDetails.kneePadding)*10,
            y: points.get(3)!.y
        });
        shapes.push(createText(points.get(14)!, "14"));

        shapes.push(createLine(points.get(13)!, this.calculateSlopeLength(points.get(13)!, points.get(11)!), this.calculateSlopeAngle(points.get(13)!, points.get(11)!), LineStyle.GUIDE));
        shapes.push(createLine(points.get(14)!, this.calculateInvertedSlopeLength(points.get(14)!, points.get(12)!), this.calculateInvertedSlopeAngle(points.get(14)!, points.get(12)!), LineStyle.GUIDE));
        shapes.push(createLine(points.get(7)!, this.calculateSlopeLength(points.get(7)!, points.get(13)!), this.calculateSlopeAngle(points.get(7)!, points.get(13)!), LineStyle.GUIDE));
        shapes.push(createLine(points.get(6)!, this.calculateInvertedSlopeLength(points.get(6)!, points.get(14)!), this.calculateInvertedSlopeAngle(points.get(6)!, points.get(14)!), LineStyle.GUIDE));
        shapes.push(createLine(points.get(10)!, this.calculateSlopeLength(points.get(10)!, points.get(6)!), this.calculateSlopeAngle(points.get(10)!, points.get(6)!), LineStyle.GUIDE));

        const pointBelowKneeLeft = {
            x: points.get(13)!.x,
            y: points.get(13)!.y + (formDetails.kneeLength /2 * 10)
        };
        shapes.push(createBezierCurveFromCoordinates(points.get(7)!, pointBelowKneeLeft, points.get(11)!, FRONT_PANT_LEFT_CURVE_T));

        const pointBelowKneeRight = {
            x: points.get(14)!.x,
            y: points.get(14)!.y + (formDetails.kneeLength /2 * 10)
        };

        const pointBetween6And14 = {
            x: points.get(14)!.x + (points.get(6)!.x - points.get(14)!.x)/2,
            y: points.get(6)!.y + (points.get(14)!.y - points.get(6)!.y)/2,
        }

        shapes.push(createBezierCurveFromCoordinates(pointBetween6And14, pointBelowKneeRight, points.get(12)!, FRONT_PANT_LEFT_CURVE_T));
        shapes.push(createBezierCurveFromCoordinates(points.get(10)!, points.get(6)!, pointBetween6And14, FRONT_PANT_LEFT_CURVE_T));


        return {
            shapes: shapes,
            points: points,
            sketchHeight: canvasHeight
        }
    }

    inferFrontPieceShapes(canvasWidth: number) : SketchResult {
        const shapes: SketchShape[] = [];
        const points :  Map<number, Coordinate> = new Map();
        let formDetails = getCurrentSavedSettings().pants;
        let commonShapes = this.inferCommonPantShapes(canvasWidth);

        return {
            shapes: commonShapes.shapes,
            points: commonShapes.points,
            sketchHeight: commonShapes.sketchHeight,
        }

    }

    inferBackPieceShapes(canvasWidth: number) : SketchResult {
        const shapes: SketchShape[] = [];
        const points :  Map<number, Coordinate> = new Map();
        let formDetails = getCurrentSavedSettings().pants;
        let commonShapes = this.inferCommonPantShapes(canvasWidth);

        return {
            shapes: commonShapes.shapes,
            points: commonShapes.points,
            sketchHeight: commonShapes.sketchHeight,
        }

    }


    render() {
        let formDetails = getCurrentSavedSettings().pants;
        const canvasWidth = 1000;
        let frontPantSketch = this.inferFrontPieceShapes(canvasWidth);
        let backPantSketch = this.inferBackPieceShapes(canvasWidth);

        return (
            <div className="TshirtPattern-Wrapper">
                <Form title={"Measurements"} resetFunction={() => { this.handleFormUpdate(getDefaultPantsMeasurements())}}
                      fields={
                    [
                        {
                            value: this.state.seamAllowance,
                            label: "Seam Allowance",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({seamAllowance: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.waistband,
                            label: "Waistband",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({waistband: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.seat,
                            label: "Seat",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({seat: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.bodyRise,
                            label: "Body Rise",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({bodyRise: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.insideLeg,
                            label: "Inside Leg",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({insideLeg: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.bottomHem,
                            label: "Bottom Hem",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({bottomHem: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.waistbandHeight,
                            label: "Waistband Height",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({waistbandHeight: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.kneeHeight,
                            label: "Knee Height",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({kneeHeight: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.seatPointRatio,
                            label: "Seat Point Ratio",
                            updateFunction: (e) => this.handleFormUpdate({seatPointRatio: e}),
                            type: FormFieldType.text,
                            infoDetails: <div>This is the ratio of how much we should go up from the start of the bodyRise line, to represent the point where the bum arches out.</div>
                        },
                        {
                            value: this.state.legCurveSlope,
                            label: "Leg Curve Slope",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({legCurveSlope: e}),
                            type: FormFieldType.text,
                            infoDetails: <div>This represents how much distance is lost when comparing vertical lines along the leg above the crotch. This amount is reduced towards the middle as the fabric will slope towards it, and added towards the edge as the fabric slopes further away</div>
                        },
                        {
                            value: this.state.kneePadding,
                            label: "Knee Padding",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({legCurveSlope: e}),
                            type: FormFieldType.text,
                            infoDetails: <div>This represents how much space is left around the knee for it to move</div>
                        },
                        {
                            value: this.state.kneeLength,
                            label: "Knee Length",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({kneeLength: e}),
                            type: FormFieldType.text,
                            infoDetails: <div>This should match the height of the knee, used to ensure that the leg goes straight down from below this point.</div>
                        },
                        {
                            value: this.state.buttAdjustmentRatio,
                            label: "Butt Adjustment Ratio",
                            updateFunction: (e) => this.handleFormUpdate({kneeLength: e}),
                            type: FormFieldType.text,
                            infoDetails: <div>This is used to adjust the width inwards for the back pant.</div>
                        },
                        {
                            value: this.state.backButtCurveAdjustment,
                            label: "Butt Curve Adjustment",
                            updateFunction: (e) => this.handleFormUpdate({backButtCurveAdjustment: e}),
                            type: FormFieldType.text,
                            infoDetails: <div>To be explained at end of back panel.</div> //TODO
                        },
                    ]
                }/>
                <PresentationForm onChangeCallback={() => this.forceUpdate()}/>
                <PrintForm/>
                <ShapeRenderer sketch={frontPantSketch} name={"Front Pant"} canvasWidth={canvasWidth} canvasHeight={frontPantSketch.sketchHeight + CANVAS_PADDING}/>
                <ShapeRenderer sketch={backPantSketch} name={"Back Pant"} canvasWidth={canvasWidth} canvasHeight={frontPantSketch.sketchHeight + CANVAS_PADDING}/>
            </div>)
            ;
    }
}