import React, {Component} from "react";
import './tshirtPattern.css';
import Form, {FormFieldType} from "./form";
import {getAllPaperSizes} from "../services/pageSize";
import {PageOrientation, PageSizeEntryMap, PrintSettings} from "../model/settings";
import {getCurrentSavedSettings, getDefaultPrintSettings, updatePrintSettings} from "../services/settings";

interface PrintingDetails {
    pageSize: string,
    pageOrientation: string,
    margins: FormMarginDetails,
}

interface FormMarginDetails {
    top: string,
    bottom: string,
    left: string,
    right: string
}

export default class PrintForm extends Component<{ }, PrintingDetails> {
    constructor(props: Readonly<{}> | {}) {
        super(props);
        const printSettings = getCurrentSavedSettings().print;
        this.state = this.convertSettingsToState(printSettings);
    }

    convertSettingsToState(settings: PrintSettings) : PrintingDetails {
        const margins: any = {};
        Object.entries(settings.margins).forEach(([k,v]) => {margins[k] = "" + v});
        return {
            pageSize : settings.pageSize,
            pageOrientation: settings.pageOrientation,
            margins: margins,
        }
    }

    handlePageUpdate(page: any) {
        const newState = {
            ...this.state,
            ...page
        }
        this.setState(newState);
        updatePrintSettings(this.convertStateToSettings(newState));
    }


    convertStateToSettings(state: PrintingDetails) : PrintSettings {
        let a : any = {};
        Object.keys(state.margins)
            .forEach(key => {
                // @ts-ignore
                a[key] = parseFloat((state.margins[key]) as string)
            });
        return {
            pageSize: state.pageSize,
            pageOrientation: Object.entries(PageOrientation).find(([k,v]) => k === state.pageOrientation)?.[1]!,
            margins: a
        }
    }

    handlePageMarginUpdate(updatedDetails: any) {
        const newState = {
            ...this.state,
            margins: {
                ...this.state.margins,
                ...updatedDetails
            }
        }
        this.setState(newState);
        updatePrintSettings(this.convertStateToSettings(newState));
    }


    render() {
        const allPaperSizes : PageSizeEntryMap = getAllPaperSizes();
        return (
            <div key={"print-form"}>
                <Form title={"Printing"} resetFunction={() => { this.handlePageUpdate(getDefaultPrintSettings())}}
                    fields={[
                    {
                        value: this.state.pageSize,
                        label: "Paper Size",
                        updateFunction: (e) => this.handlePageUpdate({ pageSize: e}),
                        type: FormFieldType.selector,
                        sourceValues: Object.keys(allPaperSizes),
                    },
                    {
                        value: this.state.margins.top,
                        label: "Top Margin",
                        updateFunction: (e) => this.handlePageMarginUpdate({top: e}),
                        suffix: "cm",
                        type: FormFieldType.text
                    },
                    {
                        value: this.state.margins.left,
                        label: "Left Margin",
                        updateFunction: (e) => this.handlePageMarginUpdate({left: e}),
                        suffix: "cm",
                        type: FormFieldType.text
                    },
                    {
                        value: this.state.margins.right,
                        label: "Right Margin",
                        updateFunction: (e) => this.handlePageMarginUpdate({right: e}),
                        suffix: "cm",
                        type: FormFieldType.text
                    },
                    {
                        value: this.state.margins.bottom,
                        label: "Bottom Margin",
                        updateFunction: (e) => this.handlePageMarginUpdate({bottom: e}),
                        suffix: "cm",
                        type: FormFieldType.text
                    },
                ]}/>
            </div>)
            ;
    }
}