import React, {Component} from "react";
import './shapeRenderer.css';
import {
    Coordinate,
    LineStyle,
    SHAPE_KEY_CIRCLE,
    SHAPE_KEY_CURVE,
    SHAPE_KEY_LINE,
    SHAPE_KEY_RECTANGLE,
    SHAPE_KEY_TEXT,
    SketchCircle,
    SketchCurve,
    SketchLine,
    SketchRectangle,
    SketchResult,
    SketchShape,
    SketchText
} from "../model/shapes";
import {createLine, TEXT_SIZE} from "../services/shapeGenerator";
import {getCurrentSavedSettings} from "../services/settings";
import {PageOrientation} from "../model/settings";
import {getAllPaperSizes} from "../services/pageSize";


interface ShapeRendererProps {
    sketch: SketchResult,
    name: string,
    canvasWidth: number,
    canvasHeight: number,
}

const guideLineDashes = "1 1";
const seamLineDashes = "20"

export default class ShapeRenderer extends Component<ShapeRendererProps,{}> {


    constructor(props: Readonly<ShapeRendererProps> | ShapeRendererProps) {
        super(props);
        this.handleSave = this.handleSave.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        // this.handlePrintForPage = this.handlePrintForPage.bind(this);
        this.splitSvgIntoPages = this.splitSvgIntoPages.bind(this);
        this.getSvgKey = this.getSvgKey.bind(this);
    }

    handleSave() {
        const svgElement: any = document.getElementById(this.getSvgKey());
        const svgText = new XMLSerializer().serializeToString(svgElement);

        const blob = new Blob([svgText], { type: "image/svg+xml" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = this.props.name.replace(/\s/g, "")+".svg";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    handlePrint() {
        const svgElement : any = document.getElementById(this.getSvgKey());
        const printWindow = window.open("", "_blank");


        const clonedSvg = svgElement.cloneNode(true) as SVGSVGElement;
        // TODO only printing first column?

        // Remove <rect> elements from the cloned SVG
        const rects = clonedSvg.querySelectorAll("rect");
        rects.forEach((rect) => rect.remove());
        printWindow!.document.write(this.splitSvgIntoPages(clonedSvg));
        printWindow!.document.close();
        printWindow!.print();
    }

    splitSvgIntoPages(svg : any) {
        const papers = getAllPaperSizes();
        const printSettings = getCurrentSavedSettings().print;
        const standardPageWidth = papers[printSettings.pageSize].mm[printSettings.pageOrientation === PageOrientation.Portrait ? 0 : 1];
        const standardPageHeight = papers[printSettings.pageSize].mm[printSettings.pageOrientation === PageOrientation.Portrait ? 1 : 0];

        const pageWidth = standardPageWidth - printSettings.margins.left*10 - printSettings.margins.right*10;
        const pageHeight= standardPageHeight - printSettings.margins.top*10 - printSettings.margins.bottom*10;
        const horizontalPages = Math.ceil(this.props.canvasWidth / pageWidth);
        const verticalPages = Math.ceil(this.props.canvasHeight / pageHeight);
        const pages = [];
        for(let x = 0; x < horizontalPages; x ++) {
            for(let y = 0; y < verticalPages; y++) {
                const clonedSvg = svg.cloneNode(true);
                const view = `${x*pageWidth} ${y*pageHeight} ${pageWidth} ${pageHeight}`;
                clonedSvg.setAttribute("viewBox", view);
                clonedSvg.setAttribute("id", "print-svg-"+x+"-"+y);
                clonedSvg.setAttribute("width", pageWidth+"mm")
                clonedSvg.setAttribute("height", pageHeight+"mm")
                this.addPageJoinGuide(clonedSvg, pageWidth, pageHeight, x, y);
                this.addRuler(clonedSvg, pageWidth, pageHeight, x, y);
                pages.push(clonedSvg);
            }
        }
        return `
    <html>
      <head>
        <style>
        svg {
              display: block;
        }
            @page {
                margin-top: ${printSettings.margins.top * 10 + "mm"};
                margin-bottom: ${printSettings.margins.bottom * 10+ "mm"};
                margin-left: ${printSettings.margins.left  * 10+ "mm"};
                margin-right: ${printSettings.margins.right * 10+ "mm"};
            }
          @media print {
            svg {
              transform: scale(1);
              /*overflow: visible;*/
              display: block;
            }
          }
        </style>
      </head>
      <body style="margin:0">
        ${pages.map(e => e.outerHTML).join('')}
      </body>
    </html>
  `;
    }

    addPageJoinGuide(svg: any, pageWidth: number, pageHeight: number, x: number, y: number) {
        const topLeftPoint : Coordinate = {
            x: pageWidth*x,
            y: pageHeight*y
        }
        svg.appendChild(this.createGuide(topLeftPoint, 10, 0, "print-guide-"+x+"-"+y+"-topLeftA"));
        svg.appendChild(this.createGuide(topLeftPoint, 0, 10, "print-guide-"+x+"-"+y+"-topLeftB"));
        const topRightPoint : Coordinate = {
            x: pageWidth*(1+x),
            y: pageHeight*y
        }
        svg.appendChild(this.createGuide(topRightPoint, -10, 0, "print-guide-"+x+"-"+y+"-topRightA"));
        svg.appendChild(this.createGuide(topRightPoint, 0, 10, "print-guide-"+x+"-"+y+"-topRightB"));
        const bottomLeftPoint : Coordinate = {
            x: pageWidth*x,
            y: pageHeight*(1+y)
        }
        svg.appendChild(this.createGuide(bottomLeftPoint, 10, 0, "print-guide-"+x+"-"+y+"-bottomLeftA"));
        svg.appendChild(this.createGuide(bottomLeftPoint, 0, -10, "print-guide-"+x+"-"+y+"-bottomLeftB"));
        const bottomRightPoint : Coordinate = {
            x: pageWidth*(1+x),
            y: pageHeight*(1+y)
        }
        svg.appendChild(this.createGuide(bottomRightPoint, -10, 0, "print-guide-"+x+"-"+y+"-bottomRightA"));
        svg.appendChild(this.createGuide(bottomRightPoint, 0, -10, "print-guide-"+x+"-"+y+"-bottomRightB"));
    }

    createGuide(startPoint: Coordinate, rightLength: number, downLength: number, name: string) {
        return this.createLine(startPoint, {x: startPoint.x + rightLength, y: startPoint.y + downLength}, name )
    }

    addRuler(svg: any,  pageWidth: number, pageHeight: number, x: number, y: number) {
        const rulerStartPoint :Coordinate = {
            x: pageWidth*x + 10,
            y: pageHeight*(1+y) - 10
        }
        svg.appendChild(this.createLine(rulerStartPoint, { x: rulerStartPoint.x + 30, y: rulerStartPoint.y}, "print-ruler-"+x+"-"+y+"-base"));
        for(let i = 0; i < 4; i++) {
            svg.appendChild(this.createLine({x: rulerStartPoint.x+(10*i), y: rulerStartPoint.y}, { x: rulerStartPoint.x+(10*i), y: rulerStartPoint.y-5}, "print-ruler-"+x+"-"+y+"-"+i+"cm"));
            if(i < 3) {
                svg.appendChild(this.createLine({x: rulerStartPoint.x+(10*i)+5, y: rulerStartPoint.y}, { x: rulerStartPoint.x+(10*i)+5, y: rulerStartPoint.y-2}, "print-ruler-"+x+"-"+y+"-"+i+".5cm"));
            }
        }
        svg.appendChild(this.createText({ x: rulerStartPoint.x + 8, y: rulerStartPoint.y+9}, "3cm", "print-ruler-"+x+"-"+y+"label"));
    }

    createLine(startPoint: Coordinate, endPoint: Coordinate, name: string) {
        const  line = document.createElementNS("http://www.w3.org/2000/svg", "line");
        line.setAttribute("id", name)
        line.setAttribute("x1", "" + startPoint.x);
        line.setAttribute("y1", "" + startPoint.y);
        line.setAttribute("x2", "" + endPoint.x);
        line.setAttribute("y2", "" + endPoint.y);
        line.setAttribute("stroke", "red");
        line.setAttribute("strokeWidth", "1");
        return line;
    }

    createText(startPoint: Coordinate, text: string, name: string) {
        const  textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
        textElement.setAttribute("id", name)
        textElement.setAttribute("textAnchor", "middle")
        textElement.setAttribute("dominantBaseline", "central")
        textElement.style.fontSize ="10px";
        textElement.setAttribute("x", "" + startPoint.x);
        textElement.setAttribute("y", "" + startPoint.y);
        textElement.setAttribute("fill", "red");
        textElement.setAttribute("stroke", "red");
        textElement.setAttribute("strokeWidth", "1");
        textElement.appendChild(document.createTextNode(text));
        return textElement;
    }

    getSvgKey() {
        return "svg-" + this.props.name.replace(/\s/g, "");
    }

    getBaseShapeProps(shape: SketchShape) {
        let specificFields;
        if (shape.type === SHAPE_KEY_RECTANGLE) {
            specificFields = {
                x: shape.x,
                y: shape.y,
                transform: `rotate(${shape.rotation}, ${shape.x}, ${shape.y})`,
                fill:"white",
            }
        } else if (shape.type === SHAPE_KEY_CIRCLE) {
            specificFields = {
                cx: shape.x,
                cy: shape.y,
                fill:"white",
            }
        } else if (shape.type === SHAPE_KEY_LINE) {
            const angleInRadians = (shape.rotation * Math.PI) / 180;
            const endingX = shape.x + (shape as SketchLine).length * Math.cos(angleInRadians);
            const endingY = shape.y + (shape as SketchLine).length * Math.sin(angleInRadians);
            specificFields = {
                x1: shape.x,
                y1: shape.y,
                x2: endingX,
                y2: endingY,
                fill:"white",
            }
        } else if (shape.type === SHAPE_KEY_TEXT) {
            specificFields = {
                x: shape.x + 10,
                y: shape.y,
                fill:"black",
            }
        } else if (shape.type === SHAPE_KEY_CURVE) {
            specificFields = {
                fill:"none"
            }
        }

        return {
            stroke: "black",
            strokeWidth: "1",
            ...specificFields
        }
    }


    renderShape(details: SketchShape[]) {
        // width="auto" height="auto" viewBox={`0 0 ${CANVAS_SIZE} ${CANVAS_SIZE}`}>
        return <svg xmlns="http://www.w3.org/2000/svg" width={this.props.canvasWidth+"px"} height={this.props.canvasHeight+"px"} viewBox={`0 0 ${this.props.canvasWidth} ${this.props.canvasHeight}`} id={this.getSvgKey()}>
            {details && details.map((shape, index) => {
                if (shape.type === SHAPE_KEY_CIRCLE) {
                    return <circle key={"shape-"+index} r={`${(shape as SketchCircle).radius}`} {...this.getBaseShapeProps(shape)}/>
                } else if (shape.type === SHAPE_KEY_RECTANGLE) {
                    return <rect key={"shape-"+index} width={`${(shape as SketchRectangle).width}`}
                                 height={`${(shape as SketchRectangle).height}`} {...this.getBaseShapeProps(shape)} />
                } else if (shape.type === SHAPE_KEY_LINE) {
                    let style = (shape as SketchLine).style;
                    let dashes;
                    if (style === LineStyle.SOLID) {
                        dashes = "none";
                    } else if (style === LineStyle.GUIDE) {
                        if (!getCurrentSavedSettings().presentation.showGuideLines) {
                            return null;
                        }
                        dashes = guideLineDashes;
                    } else if (style === LineStyle.SEAM) {
                        dashes = seamLineDashes;
                    }
                    return <line key={"shape-"+index} strokeDasharray={dashes} {...this.getBaseShapeProps(shape)}/>
                } else if (shape.type === SHAPE_KEY_TEXT) {
                    if (getCurrentSavedSettings().presentation.showPointNumbers) {
                        // text-anchor = start | middle | end
                        return <text key={"shape-" + index} textAnchor={(shape as SketchText).textAnchor}
                                     dominantBaseline="central"
                                     style={{fontSize: `${(TEXT_SIZE)}px`}} {...this.getBaseShapeProps(shape)}>{(shape as SketchText).text}</text>;
                    } else {
                        return null;
                    }
                } else if (shape.type === SHAPE_KEY_CURVE) {
                    let style = (shape as SketchCurve).style;
                    let dashes;
                    if (style === LineStyle.SOLID) {
                        dashes = "none";
                    } else if (style === LineStyle.GUIDE) {
                        if (!getCurrentSavedSettings().presentation.showGuideLines) {
                            return null;
                        }
                        dashes = guideLineDashes;
                    } else if (style === LineStyle.SEAM) {
                        dashes = seamLineDashes;
                    }
                    return  <path key={"shape-"+index} strokeDasharray={dashes} d={(shape as SketchCurve).path} {...this.getBaseShapeProps(shape)}/>
                } else {
                    return <text key={"shape-"+index} x="200" y="150" textAnchor="middle" dominantBaseline="central" fill="#000">
                        Failed Rendering {shape.type}
                    </text>
                }
            })
                .filter(e => e != null)}
        </svg>
    }

    render() {
        return (
                <div className="TshirtPattern-OutputWrapper">
                    <h1>{this.props.name} <button onClick={this.handleSave}>Download</button> <button onClick={this.handlePrint}>Print</button></h1>
                    <div className={"RenderedShape"}>{this.props.sketch.error || this.renderShape(this.props.sketch.shapes)}</div>
                </div>)
            ;
    }
}