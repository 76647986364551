import {
    PageOrientation,
    PantsMeasurement,
    PresentationDetails,
    PrintSettings,
    TshirtMeasurements
} from "../model/settings";

const SETTINGS_KEY = "SEWING_SETTINGS";
const CURRENT_VERSION = 3;

let settingsCache : SavedSettings | undefined;

export interface SavedSettings {
    version: number,
    print: PrintSettings,
    presentation: PresentationDetails,
    tshirt: TshirtMeasurements,
    pants: PantsMeasurement,
}


function getDefaultSettings() : SavedSettings {
    return {
        version: CURRENT_VERSION,
        print: getDefaultPrintSettings(),
        presentation: getDefaultPresentationSettings(),
        tshirt: getDefaultTshirtMeasurements(),
        pants: getDefaultPantsMeasurements(),
    }
}

export function getDefaultPrintSettings() : PrintSettings {
    return {
        pageSize: "A4",
        pageOrientation: PageOrientation.Portrait,
        margins: {
            top: 1,
            bottom: 1,
            left: 1,
            right: 1
        }
    }
}

export function getDefaultPresentationSettings() : PresentationDetails {
    return {
        mirrorContent: true,
        showGuideLines: true,
        showPointNumbers: true
    }
}

export function getDefaultTshirtMeasurements() : TshirtMeasurements {
    return {
        seamAllowance: 1.25,
        chest: 95.25,
        armscyeHeight: 22.86,
        neckToWaist: 48.26,
        shirtLength: 63.5,
        neck: 38.1,
        halfBack: 20.32,
        longSleeveLength: 60.96,
        shortSleeveLength: 20.32,
        bicep: 26.67,
        wrist: 16.51,
        backOfNeckCurve: 3.25,
        shoulderTopSlopeAdjustment: 2,
        shoulderFrontSlopeAdjustment: 1.25,
        armscyeAdjustmentFrontVsBack: 0.75,
        sleeveSlopeDivider: 3,
        sleeveSlack: 5,
        sleeveCurveRatio: (1.25/0.75),
    };
}

export function getDefaultPantsMeasurements() : PantsMeasurement {
    return {
        seamAllowance: 1.25,
        waistband:85,
        seat: 101.6,
        bodyRise: 25.4,
        insideLeg: 83.8,
        bottomHem:36.8,
        waistbandHeight: 5,
        kneeHeight: 50.8,
        seatPointRatio: 0.25,
        legCurveSlope: 1.25,
        kneePadding: 2,
        kneeLength: 10,
        buttAdjustmentRatio: 0.25,
        backButtCurveAdjustment: 0.6,
    };
}

export function getCurrentSavedSettings() : SavedSettings {
    if (settingsCache) {
        return settingsCache;
    }
    // Retrieve the JSON string from local storage
    let storedJsonString = localStorage.getItem(SETTINGS_KEY);
    let storedObject : SavedSettings;
    if (storedJsonString) {
        storedObject = JSON.parse(storedJsonString)
        if (storedObject.version < 2) {
            storedObject.presentation = getDefaultPresentationSettings();
            updateSettings(storedObject);
        }
        if(storedObject.version < 3) {
            storedObject.tshirt = getDefaultTshirtMeasurements();
            updateSettings(storedObject);
        }
    } else {
        storedObject = getDefaultSettings();
        updateSettings(storedObject);
    }
    settingsCache = storedObject;
    return settingsCache;
}

export function updatePrintSettings(newSettings: PrintSettings) {
    let currentSettings = getCurrentSavedSettings();
    updateSettings({
        ...currentSettings,
        version: CURRENT_VERSION,
        print: {
            ...currentSettings.print,
            ...newSettings,
            margins: {
                ...currentSettings.print.margins,
                ...newSettings.margins
            },
        }
    })
}


export function updatePresentationSettings(newSettings: PresentationDetails) {
    let currentSettings = getCurrentSavedSettings();
    updateSettings({
        ...currentSettings,
        version: CURRENT_VERSION,
        presentation: {
            ...currentSettings.presentation,
            ...newSettings,
        }
    })
}

export function updateTshirtMeasurementSettings(newSettings: TshirtMeasurements) {
    let currentSettings = getCurrentSavedSettings();
    updateSettings({
        ...currentSettings,
        version: CURRENT_VERSION,
        tshirt: {
            ...currentSettings.tshirt,
            ...newSettings,
        }
    })
}

export function updatePantsMeasurementSettings(newSettings: PantsMeasurement) {
    let currentSettings = getCurrentSavedSettings();
    updateSettings({
        ...currentSettings,
        version: CURRENT_VERSION,
        pants: {
            ...currentSettings.pants,
            ...newSettings,
        }
    })
}


function updateSettings(newSettings: SavedSettings) {
    newSettings.version  = CURRENT_VERSION;
    var jsonString = JSON.stringify(newSettings);
    localStorage.setItem(SETTINGS_KEY, jsonString);
    settingsCache = undefined;
}