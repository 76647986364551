import {Coordinate} from "../model/shapes";

export function calculateBezierControlPoint(startPoint: Coordinate, middlePoint: Coordinate, endPoint: Coordinate, t: number) : Coordinate {
// Let P0, P1, P2 be the control points, and Pc be your fixed point you want the curve to pass through.
// Then the Bezier curve is defined by
//
// P(t) = P0*t^2 + P1*2*t*(1-t) + P2*(1-t)^2
// ...where t goes from zero to 1.
//
// There are an infinite number of answers, since it might pass through your point for any value of t... So just pick one, like t=0.5, and solve for P1:
//
// Pc = P0*.25 + P1*2*.25 + P2*.25
//
// P1 = (Pc - P0*.25 - P2*.25)/.5
//    = 2*Pc - P0/2 - P2/2
// There the "P" values are (x,y) pairs, so just apply the equation once for x and once for y:
//
// x1 = 2*xc - x0/2 - x2/2
// y1 = 2*yc - y0/2 - y2/2
// ...where (xc,yc) is the point you want it to pass through, (x0,y0) is the start point, and (x2,y2) is the end point. This will give you a Bezier that passes through (xc,yc) at t=0.5.

    const controlPointX = middlePoint.x/(2*t*(1-t))-startPoint.x*t/(2*(1-t))-endPoint.x*(1-t)/(2*t)
    const controlPointY = middlePoint.y/(2*t*(1-t))-startPoint.y*t/(2*(1-t))-endPoint.y*(1-t)/(2*t)

    return { x: controlPointX, y: controlPointY };
}

export function calculateBezierCurveLength(startPoint: Coordinate, endPoint : Coordinate, controlPoint: Coordinate) {
    const numSteps = 1000; // Number of steps for numerical integration
    let curveLength = 0;

    for (let i = 0; i < numSteps; i++) {
        const t1 = i / numSteps;
        const t2 = (i + 1) / numSteps;

        const p1 = calculateQuadraticBezierPoint(startPoint, endPoint, controlPoint, t1);
        const p2 = calculateQuadraticBezierPoint(startPoint, endPoint, controlPoint, t2);

        const dx = p2.x - p1.x;
        const dy = p2.y - p1.y;

        curveLength += Math.sqrt(dx * dx + dy * dy);
    }

    return curveLength;
}

function calculateQuadraticBezierPoint(startPoint: Coordinate, endPoint : Coordinate, controlPoint: Coordinate, t: number) {
    const x = (1 - t) * (1 - t) * startPoint.x + 2 * (1 - t) * t * controlPoint.x + t * t * endPoint.x;
    const y = (1 - t) * (1 - t) * startPoint.y + 2 * (1 - t) * t * controlPoint.y + t * t * endPoint.y;

    return { x, y };
}

function calculateQuadraticY(a: number, b: number, c: number, x: number) {
    return a * x ** 2 + b * x + c;
}


export function toRadians(n : number) {
    return (Math.PI/180) * n;
}

export function fromRadians(n : number) {
    return (180/Math.PI) * n;
}