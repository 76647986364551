import React, {Component} from "react";
import './tshirtPattern.css';
import Form, {FormFieldType} from "./form";
import {PresentationDetails} from "../model/settings";
import {
    getCurrentSavedSettings, getDefaultPresentationSettings,
    getDefaultPrintSettings,
    updatePresentationSettings,
    updatePrintSettings
} from "../services/settings";

interface PresentationFormProps {
    onChangeCallback: (() => void)
}

export default class PresentationForm extends Component<PresentationFormProps, PresentationDetails> {

    constructor(props: Readonly<PresentationFormProps> | PresentationFormProps) {
        super(props);
        this.state = getCurrentSavedSettings().presentation;
    }

    handleDetailsUpdate(presentationSettings: any) {
        const newState = {
            ...this.state,
            ...presentationSettings
        }
        updatePresentationSettings(newState);
        this.setState(newState);
        this.props.onChangeCallback();
    }

    render() {
        return (
            <div key={"presentation-form"}>
                <Form title={"Presentation"} resetFunction={() => { this.handleDetailsUpdate(getDefaultPresentationSettings())}}
                      fields={[
                    {
                        value: this.state.showGuideLines,
                        suffix: "Guidelines",
                        updateFunction: (e) => this.handleDetailsUpdate({showGuideLines: e}),
                        type: FormFieldType.toggle
                    },
                    {
                        value: this.state.showPointNumbers,
                        suffix: "Labels",
                        updateFunction: (e) => this.handleDetailsUpdate({showPointNumbers: e}),
                        type: FormFieldType.toggle
                    },
                    {
                        value: this.state.mirrorContent,
                        suffix: "Mirror Content",
                        updateFunction: (e) => this.handleDetailsUpdate({mirrorContent: e}),
                        type: FormFieldType.toggle
                    }
                ]}/>
            </div>)
            ;
    }
}