import React, {Component} from "react";
import './comingSoon.css';
export default class ComingSoon extends Component<{}> {


    render() {
        return (
            <div className="ComingSoon-Wrapper">
                <div className="ComingSoon-ContentWrapper">
                    <div className="ComingSoon-ContentPadding"/>
                    <div className="ComingSoon-Content">
                        <div className="ComingSoon-ImageWrapper">
                            <img src={"/impawtant_business_banner.png"} alt={"ImpawtantBusiness Logo"}/>
                        </div>
                        <div className="ComingSoon-TextWrapper">
                            <div className="ComingSoon-ContentPadding"/>
                            <div className="ComingSoon-Content">
                                <h1>Website Coming Soon</h1>
                            </div>
                            <div className="ComingSoon-ContentPadding"/>
                        </div>
                    </div>
                    <div className="ComingSoon-ContentPadding"/>
                </div>
            </div>);
    }
}