import React from 'react';
import './App.css';
import PrivacyPolicy, {UtilisedApplication} from "./component/privacyPolicy";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ComingSoon from "./component/comingSoon";
import License from "./component/license";
import TshirtPattern from "./sewing/component/tshirtPattern";
import PantsPattern from "./sewing/component/pantsPattern";

function App() {
    return (
        <div className="dark-theme App-wrapper">
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path="*" element={<ComingSoon/>}/>
                        <Route path="/charades-app/privacy" element={<PrivacyPolicy supportEmail={"charades.info@impawtant.business"}
                                                                                    productName={"Charades"}
                                                                                    utilisedApplication={UtilisedApplication.NONE}
                                                                                    deviceBackupsOnStorage={true}/>}/>
                        <Route path="/gcp-project-swapper/privacy" element={<PrivacyPolicy supportEmail={"support@impawtant.business"}
                                                                                           productName={"GCP Project Swapper"}
                                                                                           utilisedApplication={UtilisedApplication.GCLOUD}
                                                                                           deviceBackupsOnStorage={false}/>}/>
                        <Route path="/cloudsql-connection-manager/license" element={<License/>} />
                        <Route path="/sewing/tshirt" element={<TshirtPattern/>} />
                        <Route path="/sewing/pants" element={<PantsPattern/>} />
                    </Routes>
                </BrowserRouter>
            </div>
            <script src="/__/firebase/8.4.1/firebase-app.js"/>
            <script src="/__/firebase/init.js"/>
        </div>

    );
}

export default App;
