import React, {Component} from "react";
import './license.css';

export default class License extends Component<{}, {}> {

    private static COMPANY_NAME : string = "Impawtant Business";
    private static LAST_UPDATE_DATE : string = "May 21, 2023"

    render() {
        return (
            <div className="License-Wrapper">
                <span>Copyright &copy; Impawtant Business - All Rights Reserved<br/>
                    Unauthorized copying of any parts of this software, via any medium is strictly prohibited<br/>
                    Proprietary and confidential<br/>
                    Written by Aaron Axisa / Impawtant Business , May 2023<br/>
                </span>
            </div>);
    }
}