import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";
import "firebase/analytics";


ReactDOM.render(
    <div className="bodyWrapper">
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </div>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


const firebaseConfig = {
    apiKey: "AIzaSyBN7gyj4RegVaWX3KYrjGiQVDKPv_Xbzo0",
    authDomain: "impawtantbusiness.firebaseapp.com",
    projectId: "impawtantbusiness",
    storageBucket: "impawtantbusiness.appspot.com",
    messagingSenderId: "381908970717",
    appId: "1:381908970717:web:f9fedf643f0f7f6c62ff34",
    measurementId: "G-G5X0EV72X2"
};

firebase.initializeApp(firebaseConfig);
