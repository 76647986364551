import React, {Component} from "react";
import './form.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons";
interface FormField {
    value: string | boolean,
    label?: string,
    updateFunction: (a: string | boolean) => void,
    type: FormFieldType,
    suffix?: string,
    sourceValues?: string[],
    infoDetails?: any,
}

export enum FormFieldType {
    text, toggle, selector
}

interface FormProps {
    title: string,
    fields: FormField[]
    subtitle?: string,
    resetFunction: () => void,
}

interface FormState {
    expanded : boolean[]
}

export default class Form extends Component<FormProps, FormState> {


    constructor(props: Readonly<FormProps> | FormProps) {
        super(props);
        this.state = {
            expanded: Array(props.fields.length).fill(false)
        }
    }

    renderInputValue (f: FormField) : any{
        switch(f.type) {
            case FormFieldType.text:
                return <input type="text"
                       name={f.label}  placeholder={f.label} onChange={(e) => f.updateFunction(e.target.value)}
                              key={this.getFieldKey(f)}
                       value={f.type === FormFieldType.text ? f.value as string : '' + f.value}/>
            case FormFieldType.toggle:
                return <input type="checkbox"
                              key={this.getFieldKey(f)}
                              name={f.label}  placeholder={f.label} onChange={(e) => f.updateFunction(!f.value)}
                              value={'' + f.value}
                              {...(f.value as boolean ? { checked: true} : {})} />;
            case FormFieldType.selector:
                return <select
                    key={this.getFieldKey(f)}
                    onChange={(e) => f.updateFunction(e.target.value)} value={f.value as string}>
                    {f.sourceValues!.map(v => <option key={this.getFieldKey(f) + "-option-"+v} value={v}>{v}</option>)}
                </select>;
        }
    }

    getFieldKey(f: FormField): string {
        return "Form-Field-"+(f.label ? f.label : f.suffix);
    }

    render() {
        return (
            <div><h1>{this.props.title + " "}<button className={"ButtonCancel"} onClick={this.props.resetFunction}>Reset to Defaults</button></h1>
                <div className="TshirtPattern-FormWrapper">
                    { this.props.subtitle && <div key={"Subtitle"}><span><i>{this.props.subtitle}</i></span><br/></div>}
                    { this.props.fields.map((f,i) => <div className={"FormFieldWrapper"}><div key={"Form-"+(f.label ? f.label : f.suffix)} className={"TshirtPattern-FormField"}>
                        {f.label && <label>{f.label}</label>}
                        {this.renderInputValue(f)}
                        {f.suffix && <span>{f.suffix}</span>}
                        {f.infoDetails && <div>
                            <div className={"FormField-Question"}
                                onClick={() => {
                                const newExpanded = this.state.expanded;
                                newExpanded[i] = !newExpanded[i];
                                this.setState({expanded: newExpanded});
                            }}><FontAwesomeIcon icon={faQuestionCircle}/></div>
                        </div>}
                        <br/>
                    </div>
                        {this.state.expanded[i] && <i>{f.infoDetails}</i>}
                    </div>)}
                </div>
            </div>
        );
    }
}