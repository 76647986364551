import {
    Coordinate, LineStyle,
    SHAPE_KEY_CIRCLE, SHAPE_KEY_CURVE, SHAPE_KEY_LINE,
    SHAPE_KEY_RECTANGLE, SHAPE_KEY_TEXT,
    SketchCircle, SketchCurve,
    SketchLine,
    SketchRectangle, SketchShape, SketchText, TextPlacement
} from "../model/shapes";
import {calculateBezierControlPoint} from "./mathsUtils";


export const TEXT_SIZE = 20;
export function createCircle(point: Coordinate, radius: number): SketchCircle {
    return {
        type: SHAPE_KEY_CIRCLE,
        x: point.x,
        y: point.y,
        radius: radius,
        rotation: 0
    }
}

export function createRectangle(startPoint: Coordinate, width: number, height: number, rotation: number): SketchRectangle {
    return {
        type: SHAPE_KEY_RECTANGLE,
        x: startPoint.x,
        y: startPoint.y,
        height: height,
        width: width,
        rotation: rotation
    }
}
export function createLine(coord : Coordinate, length: number, rotation: number, lineStyle?: LineStyle) : SketchLine {
    return {
        type: SHAPE_KEY_LINE,
        x: coord.x,
        y: coord.y,
        length: length,
        rotation: rotation,
        style: lineStyle ? lineStyle : LineStyle.SOLID
    }
}

export function createText(coord: Coordinate, text: string, textPlacement?: TextPlacement) : SketchText {
    const placement = textPlacement ? textPlacement : TextPlacement.BottomRight
    return {
        type: SHAPE_KEY_TEXT,
        x: coord.x - ((placement === TextPlacement.BottomLeft || placement === TextPlacement.TopLeft) ? TEXT_SIZE: 0),
        y: coord.y + (((placement === TextPlacement.BottomRight || placement === TextPlacement.BottomLeft) ? 1 : -1) * (TEXT_SIZE /2)),
        text: text,
        rotation: 0,
        textAnchor: (placement === TextPlacement.BottomLeft ||  placement === TextPlacement.TopLeft) ? "end" : "start",
        textPlacement: placement,
        sourceCoordinates: coord
    }
}


export function createCurve(startPoint: Coordinate, endPoint: Coordinate, controlPoint1: Coordinate, controlPoint2?: Coordinate, controlPoint3?: Coordinate, lineStyle?: LineStyle) : SketchCurve {
    return {
        type: SHAPE_KEY_CURVE,
        path: `M${startPoint.x},${startPoint.y} ${controlPoint2 ? "C": "Q"}${controlPoint1.x},${controlPoint1.y} ${controlPoint2 ? `${controlPoint2.x},${controlPoint2.y} ` : ""} ${controlPoint3? `${controlPoint3.x},${controlPoint3.y} `: ""}${endPoint.x}, ${endPoint.y}`,
        x: 0, y: 0, rotation: 0,
        style: lineStyle ? lineStyle : LineStyle.SOLID,
        rawPoints: {
            startPoint: startPoint,
            endPoint: endPoint,
            controlPoint1: controlPoint1,
            controlPoint2: controlPoint2,
            controlPoint3: controlPoint3
        }
    }
}

export function createBezierCurveFromCoordinates(startPoint: Coordinate, middlePoint: Coordinate, endPoint: Coordinate, tValue: number, lineStyle?: LineStyle) : SketchCurve{
    return createCurve(startPoint, endPoint, calculateBezierControlPoint(startPoint, middlePoint, endPoint, tValue), undefined, undefined, lineStyle);
}

export function mirrorShapes(mirrorOnX: number, shapes: SketchShape[]) {
    shapes.forEach(s => {
        let mirrored = mirrorShape(mirrorOnX, s);
        if (mirrored) {
            shapes.push(mirrored);
        }
    })
}


const TEXT_PLACEMENT_MIRROR : Map<TextPlacement, TextPlacement>= new Map();
TEXT_PLACEMENT_MIRROR.set(TextPlacement.BottomRight, TextPlacement.BottomLeft);
TEXT_PLACEMENT_MIRROR.set(TextPlacement.BottomLeft, TextPlacement.BottomRight);
TEXT_PLACEMENT_MIRROR.set(TextPlacement.TopRight, TextPlacement.TopLeft);
TEXT_PLACEMENT_MIRROR.set(TextPlacement.TopLeft, TextPlacement.TopRight);
function mirrorShape(mirrorOnX: number, shape: SketchShape) : SketchShape | undefined {
    switch (shape.type) {
        case SHAPE_KEY_CIRCLE: {
            if (shape.x === mirrorOnX) {
                // circle mirrored on center line so no difference
                return;
            }
            let s = shape as SketchCircle;
            return createCircle({ x: mirrorX(mirrorOnX, s.x), y: s.y}, s.radius)
        }
        case SHAPE_KEY_CURVE: {
            let s = shape as SketchCurve;
            return createCurve({
                x: mirrorX(mirrorOnX, s.rawPoints.startPoint.x),
                y: s.rawPoints.startPoint.y
            },
                {
                    x: mirrorX(mirrorOnX, s.rawPoints.endPoint.x),
                    y: s.rawPoints.endPoint.y
                }, {
                    x: mirrorX(mirrorOnX, s.rawPoints.controlPoint1.x),
                    y: s.rawPoints.controlPoint1.y
                },
                s.rawPoints.controlPoint2 ? {
                    x: mirrorX(mirrorOnX, s.rawPoints.controlPoint2.x),
                    y: s.rawPoints.controlPoint2.y
                } : undefined,
                s.rawPoints.controlPoint3 ? {
                    x: mirrorX(mirrorOnX, s.rawPoints.controlPoint3.x),
                    y: s.rawPoints.controlPoint3.y
                } : undefined,
                s.style)
        }break;
        case SHAPE_KEY_LINE: {
            let s = shape as SketchLine;
            let targetRotation = s.rotation;
            // if negative, + 360 to make positive
            while (targetRotation < 0) {
                targetRotation += 360;
            }
            while (targetRotation >= 360) {
                targetRotation -= 360;
            }

            if (s.x === mirrorOnX && (targetRotation === 90 || targetRotation === 270)) {
                // vertical line mirrored on itself
                return;
            }

            // 0-90 and 270-360 should swap with 180-90 and 270-180
            if (targetRotation === 0) {
                targetRotation = 180;
            } else if (targetRotation < 90) {
                targetRotation = 180 - targetRotation;
            } else if (targetRotation < 180 && targetRotation > 90) {
                targetRotation = 90 - (targetRotation-90);
            } else if (targetRotation < 270 && targetRotation > 180) {
                targetRotation = 270 + 90 - (targetRotation -180)
            } else if (targetRotation > 270) {
                targetRotation = 180 + 90 - (targetRotation - 270)
            }
            return createLine({ x: mirrorX(mirrorOnX, s.x), y: s.y }, s.length, targetRotation, s.style)
        }
        case SHAPE_KEY_RECTANGLE: {
            // the only rectangle should be the canvas so we do not mirror it
            return undefined;
        }
        case SHAPE_KEY_TEXT: {
            let s = shape as SketchText;
            if (s.sourceCoordinates.x === mirrorOnX) {
                // no need to mirror it as it is on the mirror line
                return;
            }
            return createText({
                x: mirrorX(mirrorOnX, s.sourceCoordinates.x),
                y: s.sourceCoordinates.y
            }, s.text + "`", TEXT_PLACEMENT_MIRROR.get(s.textPlacement));
        }
    }
    return;
}

function mirrorX(mirrorOnX: number, x: number) {
    return mirrorOnX - (x - mirrorOnX)
}