import {PageSizeEntryMap} from "../model/settings";

interface PaperSizeStorage {
    iso: PageSizeEntryMap;
    northAmerican: PageSizeEntryMap;
    usBookletEnvelope: PageSizeEntryMap;
    usSquareEnvelope: PageSizeEntryMap;
    usCatalogEnvelope: PageSizeEntryMap;
    misc: PageSizeEntryMap;
    architectural: PageSizeEntryMap;
    businessCard: PageSizeEntryMap;
    usPressSheet: PageSizeEntryMap;
    usCommercialEnvelope: PageSizeEntryMap;
    usBaronialEnvelope: PageSizeEntryMap;
    raAndSra: PageSizeEntryMap;
    ansi: PageSizeEntryMap;
    usAnnouncementEnvelope: PageSizeEntryMap;
}


const PAPER_SIZES : PaperSizeStorage = {
    iso : {
        "A10": {
            mm: [
                26,
                37
            ],
            points: [
                74,
                105
            ],
            inches: [
                1,
                1.5
            ]
        },
        "A1": {
            mm: [
                594,
                841
            ],
            points: [
                1684,
                2384
            ],
            inches: [
                23.4,
                33.1
            ]
        },
        "A0": {
            mm: [
                841,
                1189
            ],
            points: [
                2384,
                3370
            ],
            inches: [
                33.1,
                46.8
            ]
        },
        "A3": {
            mm: [
                297,
                420
            ],
            points: [
                842,
                1191
            ],
            inches: [
                11.7,
                16.5
            ]
        },
        "A2": {
            mm: [
                420,
                594
            ],
            points: [
                1191,
                1684
            ],
            inches: [
                16.5,
                23.4
            ]
        },
        "A5": {
            mm: [
                148,
                210
            ],
            points: [
                420,
                595
            ],
            inches: [
                5.8,
                8.3
            ]
        },
        "A4": {
            mm: [
                210,
                297
            ],
            points: [
                595,
                842
            ],
            inches: [
                8.3,
                11.7
            ]
        },
        "A7": {
            mm: [
                74,
                105
            ],
            points: [
                210,
                298
            ],
            inches: [
                2.9,
                4.1
            ]
        },
        "A6": {
            mm: [
                105,
                148
            ],
            points: [
                298,
                420
            ],
            inches: [
                4.1,
                5.8
            ]
        },
        "A9": {
            mm: [
                37,
                52
            ],
            points: [
                105,
                147
            ],
            inches: [
                1.5,
                2
            ]
        },
        "A8": {
            mm: [
                52,
                74
            ],
            points: [
                147,
                210
            ],
            inches: [
                2,
                2.9
            ]
        },
        "B10": {
            mm: [
                44,
                31
            ],
            points: [
                125,
                88
            ],
            inches: [
                1.7,
                1.2
            ]
        },
        "B1+": {
            mm: [
                1020,
                720
            ],
            points: [
                2891,
                2041
            ],
            inches: [
                40.2,
                28.3
            ]
        },
        "B4": {
            mm: [
                353,
                250
            ],
            points: [
                1001,
                709
            ],
            inches: [
                13.9,
                9.8
            ]
        },
        "B5": {
            mm: [
                250,
                176
            ],
            points: [
                709,
                499
            ],
            inches: [
                9.8,
                6.9
            ]
        },
        "B6": {
            mm: [
                176,
                125
            ],
            points: [
                499,
                354
            ],
            inches: [
                6.9,
                4.9
            ]
        },
        "B7": {
            mm: [
                125,
                88
            ],
            points: [
                354,
                249
            ],
            inches: [
                4.9,
                3.5
            ]
        },
        "B0": {
            mm: [
                1414,
                1000
            ],
            points: [
                4008,
                2835
            ],
            inches: [
                55.7,
                39.4
            ]
        },
        "B1": {
            mm: [
                1000,
                707
            ],
            points: [
                2835,
                2004
            ],
            inches: [
                39.4,
                27.8
            ]
        },
        "B2": {
            mm: [
                707,
                500
            ],
            points: [
                2004,
                1417
            ],
            inches: [
                27.8,
                19.7
            ]
        },
        "B3": {
            mm: [
                500,
                353
            ],
            points: [
                1417,
                1001
            ],
            inches: [
                19.7,
                13.9
            ]
        },
        "B2+": {
            mm: [
                720,
                520
            ],
            points: [
                2041,
                1474
            ],
            inches: [
                28.3,
                20.5
            ]
        },
        "B8": {
            mm: [
                88,
                62
            ],
            points: [
                249,
                176
            ],
            inches: [
                3.5,
                2.4
            ]
        },
        "B9": {
            mm: [
                62,
                44
            ],
            points: [
                176,
                125
            ],
            inches: [
                2.4,
                1.7
            ]
        },
        "C10": {
            mm: [
                40,
                28
            ],
            points: [
                113,
                79
            ],
            inches: [
                1.6,
                1.1
            ]
        },
        "C9": {
            mm: [
                57,
                40
            ],
            points: [
                162,
                113
            ],
            inches: [
                2.2,
                1.6
            ]
        },
        "C8": {
            mm: [
                81,
                57
            ],
            points: [
                230,
                162
            ],
            inches: [
                3.2,
                2.2
            ]
        },
        "C3": {
            mm: [
                458,
                324
            ],
            points: [
                1298,
                918
            ],
            inches: [
                18,
                12.8
            ]
        },
        "C2": {
            mm: [
                648,
                458
            ],
            points: [
                1837,
                1298
            ],
            inches: [
                25.5,
                18
            ]
        },
        "C1": {
            mm: [
                917,
                648
            ],
            points: [
                2599,
                1837
            ],
            inches: [
                36.1,
                25.5
            ]
        },
        "C0": {
            mm: [
                1297,
                917
            ],
            points: [
                3677,
                2599
            ],
            inches: [
                51.5,
                36.1
            ]
        },
        "C7": {
            mm: [
                114,
                81
            ],
            points: [
                323,
                230
            ],
            inches: [
                4.5,
                3.2
            ]
        },
        "C6": {
            mm: [
                162,
                114
            ],
            points: [
                459,
                323
            ],
            inches: [
                6.4,
                4.5
            ]
        },
        "C5": {
            mm: [
                229,
                162
            ],
            points: [
                649,
                459
            ],
            inches: [
                9,
                6.4
            ]
        },
        "C4": {
            mm: [
                324,
                229
            ],
            points: [
                918,
                649
            ],
            inches: [
                12.8,
                9
            ]
        }
    },
    northAmerican: {
        "Legal": {
            mm: [
                216,
                356
            ],
            points: [
                612,
                1009
            ],
            inches: [
                8.5,
                14
            ]
        },
        "Junior Legal": {
            mm: [
                127,
                203
            ],
            points: [
                360,
                575
            ],
            inches: [
                5,
                8
            ]
        },
        "Government-Letter": {
            mm: [
                203.2,
                266.7
            ],
            points: [
                576,
                756
            ],
            inches: [
                8,
                10.5
            ]
        },
        "Letter": {
            mm: [
                216,
                279
            ],
            points: [
                612,
                791
            ],
            inches: [
                8.5,
                11
            ]
        },
        "Tabloid": {
            mm: [
                279,
                432
            ],
            points: [
                791,
                1225
            ],
            inches: [
                11,
                17
            ]
        },
        "Ledger": {
            mm: [
                432,
                279
            ],
            points: [
                1225,
                791
            ],
            inches: [
                17,
                11
            ]
        }
    },
    usBookletEnvelope: {
        "6.5 Booklet envelope": {
            mm: [
                152.4,
                228.6
            ],
            points: [
                432,
                648
            ],
            inches: [
                6,
                9
            ]
        },
        "4.5 Booklet envelope": {
            mm: [
                139.7,
                190.5
            ],
            points: [
                396,
                540
            ],
            inches: [
                5.5,
                7.5
            ]
        },
        "13 Booklet envelope": {
            mm: [
                254,
                330.2
            ],
            points: [
                720,
                936
            ],
            inches: [
                10,
                13
            ]
        },
        "6 Booklet envelope": {
            mm: [
                146.05,
                225.425
            ],
            points: [
                414,
                639
            ],
            inches: [
                5.75,
                8.875
            ]
        },
        "6.625 Booklet envelope": {
            mm: [
                152.4,
                241.3
            ],
            points: [
                432,
                684
            ],
            inches: [
                6,
                9.5
            ]
        },
        "10 Booklet envelope": {
            mm: [
                241.3,
                320.675
            ],
            points: [
                684,
                909
            ],
            inches: [
                9.5,
                12.625
            ]
        },
        "9 Booklet envelope": {
            mm: [
                222.25,
                292.1
            ],
            points: [
                630,
                828
            ],
            inches: [
                8.75,
                11.5
            ]
        },
        "9.5 Booklet envelope": {
            mm: [
                228.6,
                304.8
            ],
            points: [
                648,
                864
            ],
            inches: [
                9,
                12
            ]
        },
        "6.75 Booklet envelope": {
            mm: [
                165.1,
                241.3
            ],
            points: [
                468,
                684
            ],
            inches: [
                6.5,
                9.5
            ]
        },
        "7.5 Booklet envelope": {
            mm: [
                190.5,
                266.7
            ],
            points: [
                540,
                756
            ],
            inches: [
                7.5,
                10.5
            ]
        },
        "3 Booklet envelope": {
            mm: [
                120.65,
                165.1
            ],
            points: [
                342,
                468
            ],
            inches: [
                4.75,
                6.5
            ]
        },
        "7.25 Booklet envelope": {
            mm: [
                177.8,
                254
            ],
            points: [
                504,
                720
            ],
            inches: [
                7,
                10
            ]
        }
    },
    usSquareEnvelope: {
        "7.5 Square envelope": {
            mm: [
                190.5,
                190.5
            ],
            points: [
                540,
                540
            ],
            inches: [
                7.5,
                7.5
            ]
        },
        "9 Square envelope": {
            mm: [
                228.6,
                228.6
            ],
            points: [
                648,
                648
            ],
            inches: [
                9,
                9
            ]
        },
        "7 Square envelope": {
            mm: [
                177.8,
                177.8
            ],
            points: [
                504,
                504
            ],
            inches: [
                7,
                7
            ]
        },
        "6 Square envelope": {
            mm: [
                152.4,
                152.4
            ],
            points: [
                432,
                432
            ],
            inches: [
                6,
                6
            ]
        },
        "6.5 Square envelope": {
            mm: [
                165.1,
                165.1
            ],
            points: [
                468,
                468
            ],
            inches: [
                6.5,
                6.5
            ]
        },
        "8 Square envelope": {
            mm: [
                203.2,
                203.2
            ],
            points: [
                576,
                576
            ],
            inches: [
                8,
                8
            ]
        },
        "9.5 Square envelope": {
            mm: [
                241.3,
                241.3
            ],
            points: [
                684,
                684
            ],
            inches: [
                9.5,
                9.5
            ]
        },
        "8.5 Square envelope": {
            mm: [
                215.9,
                215.9
            ],
            points: [
                612,
                612
            ],
            inches: [
                8.5,
                8.5
            ]
        }
    },
    usCatalogEnvelope: {
        "1.75 Catalog envelope": {
            mm: [
                165.1,
                241.3
            ],
            points: [
                468,
                684
            ],
            inches: [
                6.5,
                9.5
            ]
        },
        "15.5 Catalog envelope": {
            mm: [
                304.8,
                393.7
            ],
            points: [
                864,
                1116
            ],
            inches: [
                12,
                15.5
            ]
        },
        "9.75 Catalog envelope": {
            mm: [
                222.25,
                285.75
            ],
            points: [
                630,
                810
            ],
            inches: [
                8.75,
                11.25
            ]
        },
        "6 Catalog envelope": {
            mm: [
                190.5,
                266.7
            ],
            points: [
                540,
                756
            ],
            inches: [
                7.5,
                10.5
            ]
        },
        "12.5 Catalog envelope": {
            mm: [
                241.3,
                317.5
            ],
            points: [
                684,
                900
            ],
            inches: [
                9.5,
                12.5
            ]
        },
        "1 Catalog envelope": {
            mm: [
                152.4,
                228.6
            ],
            points: [
                432,
                648
            ],
            inches: [
                6,
                9
            ]
        },
        "3 Catalog envelope": {
            mm: [
                177.8,
                254
            ],
            points: [
                504,
                720
            ],
            inches: [
                7,
                10
            ]
        },
        "14.5 Catalog envelope": {
            mm: [
                292.1,
                368.3
            ],
            points: [
                828,
                1044
            ],
            inches: [
                11.5,
                14.5
            ]
        },
        "13.5 Catalog envelope": {
            mm: [
                254,
                330.2
            ],
            points: [
                720,
                936
            ],
            inches: [
                10,
                13
            ]
        },
        "10.5 Catalog envelope": {
            mm: [
                228.6,
                304.8
            ],
            points: [
                648,
                864
            ],
            inches: [
                9,
                12
            ]
        }
    },
    misc: {
        "1/3 A4": {
            mm: [
                99,
                210
            ],
            points: [
                281,
                595
            ],
            inches: [
                3.9,
                8.3
            ]
        },
        "A4 square": {
            mm: [
                210,
                210
            ],
            points: [
                595,
                595
            ],
            inches: [
                8.3,
                8.3
            ]
        },
        "Gutenberg Bible": {
            mm: [
                280,
                398
            ],
            points: [
                794,
                1128
            ],
            inches: [
                11,
                15.7
            ]
        },
        "A format paperback": {
            mm: [
                110,
                178
            ],
            points: [
                312,
                505
            ],
            inches: [
                4.3,
                7
            ]
        },
        "CD case insert": {
            mm: [
                120,
                120
            ],
            points: [
                340,
                340
            ],
            inches: [
                4.7,
                4.7
            ]
        },
        "B format paperback": {
            mm: [
                130,
                198
            ],
            points: [
                369,
                561
            ],
            inches: [
                5.1,
                7.8
            ]
        },
        "48 sheet billboard": {
            mm: [
                6096,
                3048
            ],
            points: [
                17280,
                8640
            ],
            inches: [
                240,
                120
            ]
        }
    },
    architectural: {
        "Arch E1": {
            mm: [
                762,
                1067
            ],
            points: [
                2160,
                3025
            ],
            inches: [
                30,
                42
            ]
        },
        "Arch E2": {
            mm: [
                660,
                965
            ],
            points: [
                1871,
                2735
            ],
            inches: [
                26,
                38
            ]
        },
        "Arch E3": {
            mm: [
                686,
                991
            ],
            points: [
                1945,
                2809
            ],
            inches: [
                27,
                39
            ]
        },
        "Arch D": {
            mm: [
                610,
                914
            ],
            points: [
                1729,
                2591
            ],
            inches: [
                24,
                36
            ]
        },
        "Arch E": {
            mm: [
                914,
                1219
            ],
            points: [
                2591,
                3455
            ],
            inches: [
                36,
                48
            ]
        },
        "Arch B": {
            mm: [
                305,
                457
            ],
            points: [
                865,
                1295
            ],
            inches: [
                12,
                18
            ]
        },
        "Arch C": {
            mm: [
                457,
                610
            ],
            points: [
                1295,
                1729
            ],
            inches: [
                18,
                24
            ]
        },
        "Arch A": {
            mm: [
                229,
                305
            ],
            points: [
                649,
                865
            ],
            inches: [
                9,
                12
            ]
        }
    },
    businessCard: {
        "Business card (US)": {
            mm: [
                51,
                89
            ],
            points: [
                145,
                252
            ],
            inches: [
                2,
                3.5
            ]
        },
        "Business card (UK)": {
            mm: [
                55,
                85
            ],
            points: [
                156,
                241
            ],
            inches: [
                2.2,
                3.3
            ]
        },
        "Business card (Japan)": {
            mm: [
                55,
                91
            ],
            points: [
                156,
                258
            ],
            inches: [
                2.2,
                3.6
            ]
        }
    },
    usPressSheet: {
        "12 x 18": {
            mm: [
                304.8,
                457.2
            ],
            points: [
                864,
                1296
            ],
            inches: [
                12,
                18
            ]
        },
        "24 x 36": {
            mm: [
                609.6,
                914.4
            ],
            points: [
                1728,
                2592
            ],
            inches: [
                24,
                36
            ]
        },
        "25 x 38": {
            mm: [
                635,
                965.2
            ],
            points: [
                1800,
                2736
            ],
            inches: [
                25,
                38
            ]
        },
        "35 x 45": {
            mm: [
                889,
                1143
            ],
            points: [
                2520,
                3240
            ],
            inches: [
                35,
                45
            ]
        },
        "38 x 50": {
            mm: [
                965.2,
                1270
            ],
            points: [
                2736,
                3600
            ],
            inches: [
                38,
                50
            ]
        },
        "11 x 17": {
            mm: [
                279.4,
                431.8
            ],
            points: [
                792,
                1224
            ],
            inches: [
                11,
                17
            ]
        },
        "19 x 25": {
            mm: [
                482.6,
                635
            ],
            points: [
                1368,
                1800
            ],
            inches: [
                19,
                25
            ]
        },
        "23 x 29": {
            mm: [
                584.2,
                736.6
            ],
            points: [
                1656,
                2088
            ],
            inches: [
                23,
                29
            ]
        },
        "20 x 26": {
            mm: [
                508,
                660.4
            ],
            points: [
                1440,
                1872
            ],
            inches: [
                20,
                26
            ]
        },
        "23 x 35": {
            mm: [
                584.2,
                889
            ],
            points: [
                1656,
                2520
            ],
            inches: [
                23,
                35
            ]
        },
        "28 x 40": {
            mm: [
                711.2,
                1016
            ],
            points: [
                2016,
                2880
            ],
            inches: [
                28,
                40
            ]
        },
        "26 x 40": {
            mm: [
                660.4,
                1016
            ],
            points: [
                1872,
                2880
            ],
            inches: [
                26,
                40
            ]
        },
        "17 x 22": {
            mm: [
                431.8,
                558.8
            ],
            points: [
                1224,
                1584
            ],
            inches: [
                17,
                22
            ]
        }
    },
    usCommercialEnvelope: {
        "7 Official envelope": {
            mm: [
                95.25,
                171.45
            ],
            points: [
                270,
                486
            ],
            inches: [
                3.75,
                6.75
            ]
        },
        "7.75 Official envelope": {
            mm: [
                98.425,
                190.5
            ],
            points: [
                279,
                540
            ],
            inches: [
                3.875,
                7.5
            ]
        },
        "8.625 Official envelope": {
            mm: [
                92.075,
                219.075
            ],
            points: [
                261,
                621
            ],
            inches: [
                3.625,
                8.625
            ]
        },
        "10 Official envelope": {
            mm: [
                104.775,
                241.3
            ],
            points: [
                297,
                684
            ],
            inches: [
                4.125,
                9.5
            ]
        },
        "12 Official envelope": {
            mm: [
                120.65,
                279.4
            ],
            points: [
                342,
                792
            ],
            inches: [
                4.75,
                11
            ]
        },
        "14 Official envelope": {
            mm: [
                127,
                292.1
            ],
            points: [
                360,
                828
            ],
            inches: [
                5,
                11.5
            ]
        },
        "6.25 Commercial envelope": {
            mm: [
                88.9,
                152.4
            ],
            points: [
                252,
                432
            ],
            inches: [
                3.5,
                6
            ]
        },
        "9 Official envelope": {
            mm: [
                98.425,
                225.425
            ],
            points: [
                279,
                639
            ],
            inches: [
                3.875,
                8.875
            ]
        },
        "6.75 Commercial envelope": {
            mm: [
                92.075,
                165.1
            ],
            points: [
                261,
                468
            ],
            inches: [
                3.625,
                6.5
            ]
        },
        "11 Official envelope": {
            mm: [
                114.3,
                263.525
            ],
            points: [
                324,
                747
            ],
            inches: [
                4.5,
                10.375
            ]
        }
    },
    usBaronialEnvelope: {
        "4 Baronial envelope": {
            mm: [
                92.075,
                130.175
            ],
            points: [
                261,
                369
            ],
            inches: [
                3.625,
                5.125
            ]
        },
        "6 Baronial envelope": {
            mm: [
                120.65,
                165.1
            ],
            points: [
                342,
                468
            ],
            inches: [
                4.75,
                6.5
            ]
        },
        "5.5 Baronial envelope": {
            mm: [
                111.125,
                146.05
            ],
            points: [
                315,
                414
            ],
            inches: [
                4.375,
                5.75
            ]
        },
        "Lee envelope": {
            mm: [
                133.35,
                184.15
            ],
            points: [
                378,
                522
            ],
            inches: [
                5.25,
                7.25
            ]
        }
    },
    raAndSra: {
        "SRA2": {
            mm: [
                450,
                640
            ],
            points: [
                1276,
                1814
            ],
            inches: [
                17.7,
                25.2
            ]
        },
        "SRA3": {
            mm: [
                320,
                450
            ],
            points: [
                907,
                1276
            ],
            inches: [
                12.6,
                17.7
            ]
        },
        "SRA4": {
            mm: [
                225,
                320
            ],
            points: [
                638,
                907
            ],
            inches: [
                8.9,
                12.6
            ]
        },
        "RA4": {
            mm: [
                215,
                305
            ],
            points: [
                609,
                865
            ],
            inches: [
                8.5,
                12
            ]
        },
        "SRA0": {
            mm: [
                900,
                1280
            ],
            points: [
                2551,
                3628
            ],
            inches: [
                35.4,
                50.4
            ]
        },
        "RA3": {
            mm: [
                305,
                430
            ],
            points: [
                865,
                1219
            ],
            inches: [
                12,
                16.9
            ]
        },
        "RA0": {
            mm: [
                860,
                1220
            ],
            points: [
                2438,
                3458
            ],
            inches: [
                33.0125,
                46.75
            ]
        },
        "RA1": {
            mm: [
                610,
                860
            ],
            points: [
                1729,
                2438
            ],
            inches: [
                24,
                33.9
            ]
        },
        "RA2": {
            mm: [
                430,
                610
            ],
            points: [
                1219,
                1729
            ],
            inches: [
                16.9,
                24
            ]
        },
        "SRA1": {
            mm: [
                640,
                900
            ],
            points: [
                1814,
                2551
            ],
            inches: [
                25.2,
                35.4
            ]
        }
    },
    ansi: {
        "ANSI C": {
            mm: [
                432,
                559
            ],
            points: [
                1225,
                1585
            ],
            inches: [
                17,
                22
            ]
        },
        "ANSI A (letter)": {
            mm: [
                216,
                279
            ],
            points: [
                612,
                791
            ],
            inches: [
                8.5,
                11
            ]
        },
        "ANSI B (ledger & tabloid)": {
            mm: [
                279,
                432
            ],
            points: [
                791,
                1225
            ],
            inches: [
                11,
                17
            ]
        },
        "ANSI E": {
            mm: [
                864,
                1118
            ],
            points: [
                2449,
                3169
            ],
            inches: [
                34,
                44
            ]
        },
        "ANSI D": {
            mm: [
                559,
                864
            ],
            points: [
                1585,
                2449
            ],
            inches: [
                22,
                34
            ]
        }
    },
    usAnnouncementEnvelope: {
        "A-6 envelope": {
            mm: [
                120.65,
                165.1
            ],
            points: [
                342,
                468
            ],
            inches: [
                4.75,
                6.5
            ]
        },
        "A-8 envelope": {
            mm: [
                139.7,
                206.375
            ],
            points: [
                396,
                585
            ],
            inches: [
                5.5,
                8.125
            ]
        },
        "A-2 envelope": {
            mm: [
                111.125,
                146.05
            ],
            points: [
                315,
                414
            ],
            inches: [
                4.375,
                5.75
            ]
        },
        "A-10 envelope": {
            mm: [
                152.4,
                241.3
            ],
            points: [
                432,
                684
            ],
            inches: [
                6,
                9.5
            ]
        },
        "A-7 envelope": {
            mm: [
                133.35,
                184.15
            ],
            points: [
                378,
                522
            ],
            inches: [
                5.25,
                7.25
            ]
        },
        "A-Slim envelope": {
            mm: [
                98.425,
                225.425
            ],
            points: [
                279,
                639
            ],
            inches: [
                3.875,
                8.875
            ]
        }
    }
};


export function getAllPaperSizes() : PageSizeEntryMap {
    const supportedLists : PageSizeEntryMap[] = [ PAPER_SIZES.iso, PAPER_SIZES.northAmerican, PAPER_SIZES.architectural, PAPER_SIZES.ansi]
    const result : PageSizeEntryMap = {};
    supportedLists.forEach(e => Object.keys(e).forEach(k => result[k] = e[k]));
    return result;
}