import React, {Component} from "react";
import './tshirtPattern.css';
import {Coordinate, LineStyle, SketchResult, SketchShape, TextPlacement} from "../model/shapes";
import {calculateBezierControlPoint, calculateBezierCurveLength, fromRadians, toRadians} from "../services/mathsUtils";
import {
    createBezierCurveFromCoordinates,
    createLine,
    createRectangle,
    createText,
    mirrorShapes,
    TEXT_SIZE
} from "../services/shapeGenerator";
import Form, {FormFieldType} from "./form";
import ShapeRenderer from "./shapeRenderer";
import PrintForm from "./printForm";
import PresentationForm from "./presentationForm";
import {
    getCurrentSavedSettings,
    getDefaultPrintSettings, getDefaultTshirtMeasurements,
    updatePrintSettings,
    updateTshirtMeasurementSettings
} from "../services/settings";
import {TshirtMeasurements} from "../model/settings";

interface FormValues {
    seamAllowance: string,
    chest: string,
    armscyeHeight: string,
    neckToWaist: string,
    shirtLength: string,
    neck: string,
    halfBack: string,
    longSleeveLength: string,
    shortSleeveLength: string,
    bicep: string,
    wrist: string,
    backOfNeckCurve: string,
    shoulderTopSlopeAdjustment: string,
    shoulderFrontSlopeAdjustment: string,
    armscyeAdjustmentFrontVsBack: string,
    sleeveSlopeDivider: string,
    sleeveSlack: string,
    sleeveCurveRatio: string,
}

const SHIRT_BASE_ARMSCYE_CURVE_T : number= 0.74;
const SHIRT_FRONT_ARMSCYE_CURVE_T : number= 0.73;
const SHIRT_BACK_ARMSCYE_CURVE_T : number= 0.75;

const SLEEVE_CURVE_T = 0.5;

const CANVAS_PADDING = TEXT_SIZE * 2;

/**
 * How accurate we wish to match the length of the sleeve curve on the sleeve cutout to the curve on the body cutout
 */
const CURVE_MATCHING_TOLERANCE_MILLEMETERS = 10;
/**
 * We do not allow the curve on the sleeve to be less than this amount of mm above/below the middle
 */
const CURVE_MINIMUM_LOWER_HEIGHT_MILLEMETERS = 5;
/**
 * We do not allow the curve on the sleeve to be more than this amount of mm above/below the middle
 */
const CURVE_MAXIMUM_LOWER_HEIGHT_MILLEMETERS = 30;


const SLEEVE_TOP_CURVE_T = 0.35;
const SLEEVE_BOTTOM_CURVE_T = 0.6;
/**
 * How much we de/increase the length of the slope to be able to draw a possible curve
 */
const CURVE_MATCHING_LENGTH_ADJUSTMENT_STEPS = 10;
export default class TshirtPattern extends Component<{}, FormValues> {


    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.handleFormUpdate = this.handleFormUpdate.bind(this);
        this.inferFrontBodySketch = this.inferFrontBodySketch.bind(this);
        this.state = this.convertSettingsToState(getCurrentSavedSettings().tshirt);
    }


    convertStateToSettings(state: FormValues) : TshirtMeasurements {
        const values: any = {};
        Object.entries(state).forEach(([k,v]) => {values[k] = parseFloat(v)});
        return values;
    }


    convertSettingsToState(settings: TshirtMeasurements) : FormValues {
        const values: any = {};
        Object.entries(settings).forEach(([k,v]) => {values[k] = "" + v});
        return values;
    }


    handleFormUpdate(updatedDetails: any) {
        let newState = {
            ...this.state,
            ...updatedDetails,
        };
        updateTshirtMeasurementSettings(this.convertStateToSettings(newState));
        this.setState(newState);
    }

    inferLongSleeveSketch(canvasWidth: number, frontBodySketch: SketchResult) : SketchResult {
        const shapes : SketchShape[] = [];
        let formDetails = getCurrentSavedSettings().tshirt;
        const expectedHeight = formDetails.longSleeveLength *10 + formDetails.seamAllowance *10 * 2
        let sketchResult = this.inferCommonSleeveShapes(canvasWidth, frontBodySketch, expectedHeight);
        sketchResult.shapes.forEach((e) => shapes.push(e));
        let points = sketchResult.points;
        if (!sketchResult.error) {

            points.set(6, {
                x: points.get(0)!.x,
                y: points.get(0)!.y + formDetails.longSleeveLength * 10
            });
            shapes.push(createText(points.get(6)!, "D", TextPlacement.BottomLeft))

            points.set(7, {
                x: points.get(0)!.x,
                y: points.get(0)!.y + formDetails.longSleeveLength * 10 / 2
            });
            // shapes.push(createText(points.get(7)!, "7", TextPlacement.BottomLeft))

            points.set(8, {
                x: points.get(7)!.x + (formDetails.bicep + formDetails.sleeveSlack) * 10 / 2 + formDetails.seamAllowance,
                y: points.get(7)!.y
            });
            // shapes.push(createText(points.get(8)!, "8", TextPlacement.BottomRight));


            points.set(9, {
                x: points.get(6)!.x + (formDetails.wrist + formDetails.sleeveSlack) * 10 / 2 + formDetails.seamAllowance,
                y: points.get(6)!.y
            });

            shapes.push(createText(points.get(9)!, "C"));
            shapes.push(createLine(points.get(6)!, points.get(9)!.x - points.get(6)!.x, 0));

            const pointBelow6 = {x: points.get(6)!.x, y: points.get(6)!.y + formDetails.seamAllowance * 10 * 2};

            shapes.push(createLine(points.get(0)!, pointBelow6.y - points.get(0)!.y, 90, LineStyle.GUIDE));

            const pointOutOf0OntoBelow6 = this.calculatePointAtReflectedAngleAndKnownY(points.get(9)!, points.get(8)!, points.get(9)!, pointBelow6!.y);
            shapes.push(createLine(points.get(9)!, this.calculateSlopeLength(points.get(9)!, pointOutOf0OntoBelow6), this.calculateSlopeAngle(points.get(9)!, pointOutOf0OntoBelow6)));
            shapes.push(createLine(pointBelow6, pointOutOf0OntoBelow6.x - pointBelow6.x, 0));

            if (Math.abs(this.calculateInvertedSlopeAngle(points.get(3)!, points.get(8)!) - this.calculateInvertedSlopeAngle(points.get(8)!, points.get(9)!)) > 5) {
                // if there is enough difference in angle, we add a curve to smoothen out the arm
                shapes.push(createBezierCurveFromCoordinates(points.get(3)!, points.get(8)!, points.get(9)!, 0.5))

                shapes.push(createLine(points.get(3)!, this.calculateSlopeLength(points.get(3)!, points.get(8)!), this.calculateInvertedSlopeAngle(points.get(3)!, points.get(8)!), LineStyle.GUIDE))
                shapes.push(createLine(points.get(8)!, this.calculateSlopeLength(points.get(8)!, points.get(9)!), this.calculateInvertedSlopeAngle(points.get(8)!, points.get(9)!), LineStyle.GUIDE))
            } else {
                shapes.push(createLine(points.get(3)!, this.calculateSlopeLength(points.get(3)!, points.get(8)!), this.calculateInvertedSlopeAngle(points.get(3)!, points.get(8)!)))
                shapes.push(createLine(points.get(8)!, this.calculateSlopeLength(points.get(8)!, points.get(9)!), this.calculateInvertedSlopeAngle(points.get(8)!, points.get(9)!)))
            }

            if (getCurrentSavedSettings().presentation.mirrorContent) {
                mirrorShapes(points.get(0)!.x, shapes);
            }
        }
        return {
            shapes: shapes,
            points: points,
            sketchHeight: expectedHeight,
            error: sketchResult.error
        };

    }

    inferShortSleeveSketch(canvasWidth: number, frontBodySketch: SketchResult) : SketchResult {
        let formDetails = getCurrentSavedSettings().tshirt;
        const shapes : SketchShape[] = [];
        const expectedHeight = formDetails.seamAllowance*10 + formDetails.shortSleeveLength * 10+ formDetails.seamAllowance *10 * 2;
        let sketchResult = this.inferCommonSleeveShapes(canvasWidth, frontBodySketch, expectedHeight);
        sketchResult.shapes.forEach((e) => shapes.push(e));
        let points = sketchResult.points;
        if (!sketchResult.error) {
            points.set(2, {
                x: points.get(0)!.x,
                y: formDetails.seamAllowance * 10 + points.get(0)!.y + formDetails.shortSleeveLength * 10
            });
            // shapes.push(createText(points.get(2)!, "2", TextPlacement.BottomLeft));

            points.set(2.5, {x: points.get(2)!.x, y: points.get(2)!.y + formDetails.seamAllowance * 10 * 2});
            shapes.push(createText(points.get(2.5)!, "D", TextPlacement.BottomLeft));

            shapes.push(createLine(points.get(0)!, points.get(2.5)!.y - points.get(0)!.y, 90, LineStyle.GUIDE));

            points.set(5, {
                x: points.get(2)!.x + (formDetails.bicep + formDetails.sleeveSlack) * 10 / 2 + formDetails.seamAllowance,
                y: points.get(2)!.y
            });
            shapes.push(createLine(points.get(3)!, this.calculateSlopeLength(points.get(3)!, points.get(5)!), this.calculateInvertedSlopeAngle(points.get(3)!, points.get(5)!)))
            shapes.push(createLine(points.get(2)!, points.get(5)!.x - points.get(2.5)!.x, 0, LineStyle.GUIDE));

            const pointOutOf5Onto2_5 = this.calculatePointAtReflectedAngleAndKnownY(points.get(5)!, points.get(3)!, points.get(5)!, points.get(2.5)!.y);
            shapes.push(createText(pointOutOf5Onto2_5, "C", TextPlacement.BottomRight));

            shapes.push(createLine(points.get(5)!, this.calculateSlopeLength(points.get(5)!, pointOutOf5Onto2_5), this.calculateSlopeAngle(points.get(5)!, pointOutOf5Onto2_5)));


            shapes.push(createLine(points.get(2.5)!, pointOutOf5Onto2_5.x - points.get(2.5)!.x, 0));

            if (getCurrentSavedSettings().presentation.mirrorContent) {
                mirrorShapes(points.get(0)!.x, shapes);
            }
        }
        return {
            shapes: shapes,
            points: points,
            sketchHeight: expectedHeight,
            error: sketchResult.error
        };
    }

    calculatePointAtReflectedAngleAndKnownY(startPoint:Coordinate, angleTopRightPoint: Coordinate, angleBottomLeftPoint: Coordinate, desiredY: number) {
        let originalAngle = this.calculateInvertedSlopeAngle(angleTopRightPoint, angleBottomLeftPoint);
        const targetAngle =  90 - originalAngle;
        // tan(targetAngle) = opposite / adjacent
        // opposite = tan(targetAngle) * adjacent
         return {
             x: startPoint.x + Math.tan(toRadians(targetAngle)) * (desiredY - startPoint.y),
             y: desiredY
         }
    }

    calculateSleeveLargeCurveDistance(sleeveCurveRatio: number, desiredLength: number, points: Map<number, Coordinate>) {
        return this.calculateSleeveLargeCurveDistanceRecursive(sleeveCurveRatio, desiredLength, points, 0);
    }

    calculateSleeveLargeCurveDistanceRecursive(sleeveCurveRatio: number, desiredLength: number, points: Map<number, Coordinate>, lengthAdjustment: number) : string | undefined {
        let formDetails = getCurrentSavedSettings().tshirt;
        const tentativePoint3 = {
            x: points.get(0)!.x + this.calculateSlopeLength({
                x: formDetails.shoulderFrontSlopeAdjustment * 10 + (formDetails.halfBack + formDetails.seamAllowance) * 10,
                y: formDetails.shoulderTopSlopeAdjustment * 10 + formDetails.backOfNeckCurve * 10
            }, {
                x: (formDetails.chest / 4 + formDetails.seamAllowance * 2) * 10,
                y: formDetails.backOfNeckCurve * 10 + formDetails.armscyeHeight * 10,
            }) + lengthAdjustment,
            y: points.get(1)!.y
        };

        if ((tentativePoint3.x - points.get(0)!.x)  < ((formDetails.bicep + formDetails.sleeveSlack) * 10 / 2 + formDetails.seamAllowance)) {
            // in this case, we have reduced the length of 4 and 3 so much that it is not feasible to cut it.
            return "Sleeve Curve cannot be shorter than the bicep length!";
        }

        const tentativePoint4 =  this.calculateCoordinatesDownSlope(points.get(0)!, tentativePoint3, 2/3);

        // we first check if the lower bound is a satisfactory result
        let lowerBoundLength = this.calculateLengthTest(sleeveCurveRatio, points.get(0)!, tentativePoint4, tentativePoint3, CURVE_MINIMUM_LOWER_HEIGHT_MILLEMETERS);
        if (Math.abs(lowerBoundLength - desiredLength) <= CURVE_MATCHING_TOLERANCE_MILLEMETERS) {
            // we have stumbled upon the right size by coincidence
            this.finalizeSleeveCurve(points, sleeveCurveRatio,  points.get(0)!, tentativePoint4, tentativePoint3, CURVE_MINIMUM_LOWER_HEIGHT_MILLEMETERS);
        } else if (lowerBoundLength > desiredLength) {
            // console.log("Minimum Length achievable is " + lowerBoundLength + " but need " + desiredLength+". Reducing length of slope");
            // this means that we need to reduce the length of points 4 and 3, or we will end up unable to generate a curve
            return this.calculateSleeveLargeCurveDistanceRecursive(sleeveCurveRatio, desiredLength, points, lengthAdjustment - CURVE_MATCHING_LENGTH_ADJUSTMENT_STEPS);
        } else {
            // we now check if the upper bound can produce a satisfactory result
            let upperBoundLength = this.calculateLengthTest(sleeveCurveRatio, points.get(0)!, tentativePoint4, tentativePoint3, CURVE_MAXIMUM_LOWER_HEIGHT_MILLEMETERS);
            if (Math.abs(upperBoundLength - desiredLength) <= CURVE_MATCHING_TOLERANCE_MILLEMETERS) {
                // we stumbled upon the right size by coincidence
                this.finalizeSleeveCurve(points, sleeveCurveRatio,  points.get(0)!, tentativePoint4, tentativePoint3, CURVE_MAXIMUM_LOWER_HEIGHT_MILLEMETERS);
            } else if (upperBoundLength < desiredLength ) {
                // console.log("Maximum Length achievable is " + upperBoundLength + " but need " + desiredLength+". Increasing length of slope");
                // the upper bound length was not long enough so we need to extend points 4 and 3
                return this.calculateSleeveLargeCurveDistanceRecursive(sleeveCurveRatio, desiredLength, points, lengthAdjustment + CURVE_MATCHING_LENGTH_ADJUSTMENT_STEPS);
            } else {
                // the slope is possible within the range that we have, so we need to figure out where it is.
                // we start in the middle and then adjust going by half in the appropriate direction

                let upperBound = CURVE_MAXIMUM_LOWER_HEIGHT_MILLEMETERS;
                let lowerBound = CURVE_MINIMUM_LOWER_HEIGHT_MILLEMETERS;
                let sleeveLargeCurveDistance = (upperBound + lowerBound) / 2;
                let calculatedLength = this.calculateLengthTest(sleeveCurveRatio, points.get(0)!, tentativePoint4, tentativePoint3, CURVE_MAXIMUM_LOWER_HEIGHT_MILLEMETERS);

                while (Math.abs(calculatedLength - desiredLength) > CURVE_MATCHING_TOLERANCE_MILLEMETERS) {
                    if (calculatedLength > desiredLength) {
                        // console.log("Higher by", calculatedLength - desiredLength, calculatedLength, desiredLength);
                        upperBound = sleeveLargeCurveDistance;
                    } else {
                        // console.log("Lower by", desiredLength - calculatedLength, calculatedLength, desiredLength);
                        lowerBound = sleeveLargeCurveDistance;
                    }
                    sleeveLargeCurveDistance = (lowerBound + upperBound) / 2;
                    calculatedLength = this.calculateLengthTest(sleeveCurveRatio, points.get(0)!, tentativePoint4, tentativePoint3, CURVE_MAXIMUM_LOWER_HEIGHT_MILLEMETERS);
                }
                this.finalizeSleeveCurve(points, sleeveCurveRatio,  points.get(0)!, tentativePoint4, tentativePoint3, sleeveLargeCurveDistance);

            }
        }

    }

    finalizeSleeveCurve(points: Map<number, Coordinate>, sleeveCurveRatio: number, point0: Coordinate, tentativePoint4: Coordinate, tentativePoint3: Coordinate, sleeveShortCurveHeight: number) {
        points.set(3, tentativePoint3);
        points.set(4, tentativePoint4);
        const point0To4Midpoint = this.calculateCoordinatesDownSlope(point0, tentativePoint4, 1/2);
        const point4To3Midpoint = this.calculateCoordinatesDownSlope(tentativePoint4, tentativePoint3, 1/2);
        const point0To4RaisedMidpoint = this.goUpfromBottomPoint(point0, point0To4Midpoint, sleeveShortCurveHeight*sleeveCurveRatio);
        const point4To3LoweredMidpoint = this.goDownFromBottomPoint(tentativePoint4, point4To3Midpoint, sleeveShortCurveHeight);

        points.set(4.012, point0To4RaisedMidpoint)
        points.set(4.31, point4To3LoweredMidpoint)

    }

    calculateLengthTest(sleeveCurveRatio: number, point0: Coordinate, tentativePoint4: Coordinate, tentativePoint3: Coordinate, sleeveShortCurveHeight: number) {
        const point0To4Midpoint = this.calculateCoordinatesDownSlope(point0, tentativePoint4, 1/2);
        const point4To3Midpoint = this.calculateCoordinatesDownSlope(tentativePoint4, tentativePoint3, 1/2);
        const point0To4RaisedMidpoint = this.goUpfromBottomPoint(point0, point0To4Midpoint, sleeveShortCurveHeight*sleeveCurveRatio);
        const point4To3LoweredMidpoint = this.goDownFromBottomPoint(tentativePoint4, point4To3Midpoint, sleeveShortCurveHeight);

        return calculateBezierCurveLength(point0, tentativePoint4, calculateBezierControlPoint(point0, point0To4RaisedMidpoint, tentativePoint4, SLEEVE_CURVE_T))
            + calculateBezierCurveLength(tentativePoint4, tentativePoint3, calculateBezierControlPoint(tentativePoint4, point4To3LoweredMidpoint, tentativePoint3, SLEEVE_CURVE_T));

    }

    inferCommonSleeveShapes(canvasWidth: number, frontBodySketch: SketchResult, heightToUse: number) : SketchResult {
        let formDetails = getCurrentSavedSettings().tshirt;
        let points : Map<number, Coordinate> = new Map();
        const shapes : SketchShape[] = [];
        // canvas
        shapes.push(createRectangle({x: 0, y: 0}, canvasWidth, heightToUse + CANVAS_PADDING, 0));

        // vertical line half-way through and point 0 if mirrored, otherwise just add the padding
        points.set(0, { x : (getCurrentSavedSettings().presentation.mirrorContent ? (canvasWidth/2) : CANVAS_PADDING), y: 0});
        shapes.push(createText(points.get(0)!, "A", TextPlacement.BottomLeft));

        points.set(1, { x: points.get(0)!.x, y: formDetails.armscyeHeight * 10 / formDetails.sleeveSlopeDivider});
        // shapes.push(createText(points.get(1)!, "1", TextPlacement.BottomLeft));


        // we need to adjust the sleeveLargeCurveDistance so that the length of the curve on the sleeve is equal to the length of the curve on the body for the armscye (17 -> 12 -> 5)
        const desiredLength = calculateBezierCurveLength(frontBodySketch.points.get(17)!,
            frontBodySketch.points.get(12)!,
            calculateBezierControlPoint(frontBodySketch.points.get(17)!,
                frontBodySketch.points.get(12)!,
                frontBodySketch.points.get(5)!,
                SHIRT_BASE_ARMSCYE_CURVE_T));

        const error = this.calculateSleeveLargeCurveDistance(formDetails.sleeveCurveRatio, desiredLength, points);//1.25

        if (! error) {
            shapes.push(createText(points.get(3)!, "B"));
            shapes.push(createLine(points.get(1)!, points.get(3)!.x - points.get(1)!.x, 0, LineStyle.GUIDE));
            shapes.push(createLine(points.get(0)!, this.calculateSlopeLength(points.get(0)!, points.get(3)!), this.calculateSlopeAngle(points.get(0)!, points.get(3)!), LineStyle.GUIDE));
            // shapes.push(createText(points.get(4)!, "4", TextPlacement.TopRight));
            // curve from 0 ->  4.012 -> 4 ->  4.31 -> 3
            shapes.push(createBezierCurveFromCoordinates(points.get(0)!, points.get(4.012)!, points.get(4)!, SLEEVE_TOP_CURVE_T));
            shapes.push(createBezierCurveFromCoordinates(points.get(4)!, points.get(4.31)!, points.get(3)!, SLEEVE_BOTTOM_CURVE_T));
        }


        return {
            shapes: shapes,
            points: points,
            sketchHeight: 0,
            error: error,
        };
    }

    inferFrontBodySketch(canvasWidth: number) : SketchResult {
        let formDetails = getCurrentSavedSettings().tshirt;
        const shapes : SketchShape[] = [];
        let sketchResult = this.inferCommonBodyShapes(canvasWidth);
        sketchResult.shapes.forEach((e) => shapes.push(e));
        let points = sketchResult.points;

        shapes.push(createText(points.get(18)!, "G", TextPlacement.BottomLeft));
        shapes.push(createLine(points.get(18)!, points.get(9)!.x - points.get(18)!.x, 0, LineStyle.GUIDE));
        shapes.push(createLine(points.get(9)!, points.get(18)!.y - points.get(9)!.y, 90, LineStyle.GUIDE));
        points.set(18.9, {x: points.get(9)!.x, y: points.get(18)!.y});

        points.set(10.1, { x: points.get(18)!.x + (points.get(10)!.x - points.get(18)!.x)/4, y: points.get(18)!.y});
        shapes.push(createLine(points.get(10)!, points.get(10.05)!.y - points.get(10)!.y, 90))
        shapes.push(createBezierCurveFromCoordinates(points.get(10.05)!, points.get(10.1)!, points.get(18)!, 0.15));

        points.set(12.05, {x: points.get(12)!.x - formDetails.armscyeAdjustmentFrontVsBack * 10, y: points.get(12)!.y});
        shapes.push(createBezierCurveFromCoordinates(points.get(17)!, points.get(12.05)!, points.get(5)!, SHIRT_FRONT_ARMSCYE_CURVE_T));

        if (getCurrentSavedSettings().presentation.mirrorContent) {
            mirrorShapes(points.get(0)!.x, shapes);
        }
        return {
            shapes: shapes,
            points: points,
            sketchHeight: sketchResult.sketchHeight
        };
    }


    inferBackBodySketch(canvasWidth: number) : SketchResult {
        let formDetails = getCurrentSavedSettings().tshirt;
        const shapes : SketchShape[] = [];
        let sketchResult = this.inferCommonBodyShapes(canvasWidth);
        sketchResult.shapes.forEach((e) => shapes.push(e));
        let points = sketchResult.points;

        shapes.push(createLine(points.get(1)!,  points.get(9)!.x - points.get(1)!.x,0, LineStyle.GUIDE));
        shapes.push(createText(points.get(1)!, "G", TextPlacement.BottomLeft));

        points.set(10.1, { x: points.get(1)!.x + (points.get(9)!.x - points.get(1)!.x)/4, y: points.get(1)!.y});
        shapes.push(createLine(points.get(10)!, points.get(10.05)!.y - points.get(10)!.y, 90))
        shapes.push(createBezierCurveFromCoordinates(points.get(10.05)!, points.get(10.1)!, points.get(1)!, 0.15));

        points.set(12.05, {x: points.get(12)!.x + formDetails.armscyeAdjustmentFrontVsBack * 10, y: points.get(12)!.y});
        shapes.push(createBezierCurveFromCoordinates(points.get(17)!, points.get(12.05)!, points.get(5)!, SHIRT_BACK_ARMSCYE_CURVE_T));

        if (getCurrentSavedSettings().presentation.mirrorContent) {
            mirrorShapes(points.get(0)!.x, shapes);
        }
        return {
            shapes: shapes,
            points: points,
            sketchHeight: sketchResult.sketchHeight
        };
    }

    
    inferCommonBodyShapes(canvasWidth: number): SketchResult  {
        let formDetails = getCurrentSavedSettings().tshirt;
        let points : Map<number, Coordinate> = new Map();
        const shapes : SketchShape[] = [];
        // canvas
        const expectedHeight = formDetails.backOfNeckCurve * 10 + formDetails.shirtLength * 10 + formDetails.seamAllowance * 2 * 10;
        shapes.push(createRectangle({x: 0, y: 0}, canvasWidth, expectedHeight + CANVAS_PADDING, 0));

        // vertical line half-way through and point 0 if mirrored, otherwise just add the padding
        points.set(0, { x : (getCurrentSavedSettings().presentation.mirrorContent ? (canvasWidth/2) : CANVAS_PADDING), y: 0});
        shapes.push(createText(points.get(0)!, "A", TextPlacement.BottomLeft));
        points.set(1, { x : points.get(0)!.x, y: formDetails.backOfNeckCurve * 10});
        // point 1 and line to the right


        points.set(2, {x:points.get(0)!.x, y: points.get(1)!.y + formDetails.armscyeHeight * 10 });
        // shapes.push(createText(points.get(2)!,"2", TextPlacement.BottomLeft));


        points.set(3,  {x: points.get(0)!.x, y: points.get(1)!.y + formDetails.neckToWaist * 10});
        // shapes.push(createText(points.get(3)!, "3", TextPlacement.BottomLeft));


        points.set(4, {x: points.get(0)!.x, y: points.get(1)!.y + formDetails.shirtLength * 10 + formDetails.seamAllowance * 2 * 10 });
        shapes.push(createText(points.get(4)!, "F", TextPlacement.BottomLeft));
        shapes.push(createLine(points.get(0)!, points.get(4)!.y - points.get(0)!.y,90, LineStyle.GUIDE));


        points.set(5, {x: points.get(0)!.x + (formDetails.chest / 4 + formDetails.seamAllowance * 2)*10, y: points.get(2)!.y});
        shapes.push(createText(points.get(5)!, "D"));
        shapes.push(createLine(points.get(2)!, points.get(5)!.x - points.get(2)!.x,0, LineStyle.GUIDE));

        points.set(6, {x: points.get(5)!.x, y:points.get(0)!.y });
        // shapes.push(createText(points.get(6)!, "6"));


        points.set(7, {x: points.get(5)!.x, y: points.get(3)!.y});
        // shapes.push(createText(points.get(7)!, "7"));
        shapes.push(createLine(points.get(3)!, points.get(7)!.x - points.get(3)!.x,0, LineStyle.GUIDE));

        points.set(8,  { x: points.get(5)!.x, y: points.get(4)!.y});
        shapes.push(createText(points.get(8)!, "E"));
        shapes.push(createLine(points.get(4)!, points.get(8)!.x - points.get(4)!.x,0));
        shapes.push(createLine(points.get(6)!,  points.get(5)!.y - points.get(6)!.y,90, LineStyle.GUIDE));
        shapes.push(createLine(points.get(5)!,  points.get(8)!.y - points.get(5)!.y,90));


        points.set(9,  { x: points.get(0)!.x + (formDetails.neck / 5) *10, y: points.get(1)!.y});
        // shapes.push(createText(points.get(9)!, "9", TextPlacement.TopRight));

        points.set(10, {x: points.get(0)!.x + (formDetails.neck / 5) *10, y: points.get(0)!.y});
        shapes.push(createText(points.get(10)!, "B", TextPlacement.BottomLeft));
        shapes.push(createLine(points.get(10)!, points.get(9)!.y - points.get(10)!.y,90, LineStyle.GUIDE));

        points.set(10.05,  { x: points.get(10)!.x, y: points.get(10)!.y + formDetails.seamAllowance * 10});

        points.set(11, { x: points.get(1)!.x, y: points.get(1)!.y + (points.get(2)!.y - points.get(1)!.y)/2});
        // shapes.push(createText(points.get(11)!, "11", TextPlacement.BottomLeft));

        points.set(12, {x: points.get(11)!.x + (formDetails.halfBack + formDetails.seamAllowance)*10, y: points.get(11)!.y});
        // shapes.push(createText(points.get(12)!, "12", TextPlacement.BottomLeft));
        shapes.push(createLine(points.get(11)!, points.get(12)!.x - points.get(11)!.x, 0, LineStyle.GUIDE));

        points.set(13, {x : points.get(12)!.x, y: points.get(5)!.y});
        points.set(14, {x: points.get(12)!.x, y: points.get(0)!.y});
        // shapes.push(createText(points.get(13)!, "13", TextPlacement.BottomLeft));
        // shapes.push(createText(points.get(14)!, "14"));
        shapes.push(createLine(points.get(14)!, points.get(13)!.y - points.get(14)!.y, 90, LineStyle.GUIDE));


        points.set(15, {x: points.get(1)!.x, y: points.get(1)!.y + formDetails.shoulderTopSlopeAdjustment * 10});
        // shapes.push(createText(points.get(15)!, "15", TextPlacement.BottomLeft));

        points.set(16,  {x: points.get(14)!.x, y: points.get(15)!.y});
        // shapes.push(createText(points.get(16)!, "16", TextPlacement.BottomLeft));

        points.set(17,  {x: points.get(16)!.x + formDetails.shoulderFrontSlopeAdjustment * 10, y: points.get(16)!.y});
        shapes.push(createText(points.get(17)!, "C"));
        shapes.push(createLine(points.get(15)!, points.get(17)!.x - points.get(15)!.x, 0, LineStyle.GUIDE));

        shapes.push(createBezierCurveFromCoordinates(points.get(17)!, points.get(12)!, points.get(5)!, SHIRT_BASE_ARMSCYE_CURVE_T, LineStyle.GUIDE));

        shapes.push(createLine(points.get(10)!, this.calculateSlopeLength(points.get(10)!, points.get(17)!), this.calculateSlopeAngle(points.get(10)!, points.get(17)!)));

        points.set(18,  {x: points.get(0)!.x, y: points.get(0)!.y + formDetails.neck /4 * 10});

        return {
            shapes: shapes,
            points: points,
            sketchHeight: expectedHeight
        };

    }

    calculateSlopeLength(topLeftPoint: Coordinate, bottomRightPoint: Coordinate) {
        return Math.sqrt(Math.pow(bottomRightPoint.y - topLeftPoint.y, 2) + Math.pow(bottomRightPoint.x - topLeftPoint.x, 2));
    }

    calculateSlopeAngle(topLeftPoint: Coordinate, bottomRightPoint: Coordinate) {
        // tan(interior angle) = opposite / adjacent
        // rotation angle = 90 - interior angle
        return 90 - fromRadians(Math.atan((bottomRightPoint.x - topLeftPoint.x) / (bottomRightPoint.y - topLeftPoint.y)));
    }
    calculateInvertedSlopeAngle(topRightPoint: Coordinate, bottomLeftPoint: Coordinate) {
        // tan(interior angle) = opposite / adjacent
        // rotation angle = 90 - interior angle
        return 180 - fromRadians(Math.atan((bottomLeftPoint.y - topRightPoint.y) / (topRightPoint.x - bottomLeftPoint.x)));
    }

    calculateCoordinatesDownSlope(topLeftPoint : Coordinate, bottomRightPoint : Coordinate, fractionDownSlope: number) : Coordinate {
        let upperAngle = this.calculateSlopeAngle(topLeftPoint, bottomRightPoint);
        let desiredDistance = this.calculateSlopeLength(topLeftPoint, bottomRightPoint) * fractionDownSlope;

        // cos(angle) = adjacent/hypotenuse
        // sin(angle) = opposite/hypotenuse
        return {
            x: topLeftPoint.x + desiredDistance * Math.cos(toRadians(upperAngle)),
            y: topLeftPoint.y + desiredDistance * Math.sin(toRadians(upperAngle)),
        }
    }

    goUpfromBottomPoint(topLeftPoint: Coordinate, bottomRightPoint: Coordinate, shortSideLength: number) : Coordinate {
        let upperSlopeAngle = 90 - this.calculateSlopeAngle(topLeftPoint, bottomRightPoint);
        return {
            x: bottomRightPoint.x + (shortSideLength * Math.sin(toRadians(upperSlopeAngle))),
            y: bottomRightPoint.y - (shortSideLength * Math.cos(toRadians(upperSlopeAngle))),
        }
    }

    goDownFromBottomPoint(topLeftPoint: Coordinate, bottomRightPoint: Coordinate, shortSideLength: number) : Coordinate {
        let upperSlopeAngle = this.calculateSlopeAngle(topLeftPoint, bottomRightPoint);
        return {
            x: bottomRightPoint.x - (shortSideLength * Math.sin(toRadians(upperSlopeAngle))),
            y: bottomRightPoint.y + (shortSideLength * Math.cos(toRadians(upperSlopeAngle))),
        }
    }



    render() {
        let formDetails = getCurrentSavedSettings().tshirt;
        const canvasWidth = ((formDetails.chest / 4 + formDetails.seamAllowance * 2)*10) * (getCurrentSavedSettings().presentation.mirrorContent ? 2 : 1) + 2* CANVAS_PADDING;
        const frontBodySketch = this.inferFrontBodySketch(canvasWidth);
        const backBodySketch = this.inferBackBodySketch(canvasWidth);
        const longSleeveSketch = this.inferLongSleeveSketch(canvasWidth, frontBodySketch);
        const shortSleeveSketch = this.inferShortSleeveSketch(canvasWidth, frontBodySketch);
        return (
            <div className="TshirtPattern-Wrapper">
                <Form title={"Measurements"} resetFunction={() => { this.handleFormUpdate(getDefaultTshirtMeasurements())}}
                      fields={
                    [
                        {
                            value: this.state.seamAllowance,
                            label: "Seam Allowance",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({seamAllowance: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.chest,
                            label: "Chest",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({chest: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.armscyeHeight,
                            label: "Armscye Height",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({armscyeHeight: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.neckToWaist,
                            label: "Neck To Waist",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({neckToWaist: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.shirtLength,
                            label: "Shirt Length",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({shirtLength: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.neck,
                            label: "Neck",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({neck: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.halfBack,
                            label: "Half Back",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({halfBack: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.longSleeveLength,
                            label: "Long Sleeve Length",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({longSleeveLength: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.shortSleeveLength,
                            label: "Short Sleeve Length",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({shortSleeveLength: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.bicep,
                            label: "Bicep",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({bicep: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.wrist,
                            label: "Wrist",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({wrist: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.backOfNeckCurve,
                            label: "Back of Neck Curve Adjustment",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({backOfNeckCurve: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.shoulderTopSlopeAdjustment,
                            label: "Shoulder Top Slope Adjustment",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({shoulderTopSlopeAdjustment: e}),
                            type: FormFieldType.text,
                            infoDetails: <div>Should be between 1.25 and 2.5cm</div>
                        },
                        {
                            value: this.state.shoulderFrontSlopeAdjustment,
                            label: "Shoulder Front Slope Adjustment",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({shoulderFrontSlopeAdjustment: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.armscyeAdjustmentFrontVsBack,
                            label: "Front vs Back Armscye Adjustment",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({armscyeAdjustmentFrontVsBack: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.sleeveSlopeDivider,
                            label: "Sleeve Slope Adjustment Divider",
                            updateFunction: (e) => this.handleFormUpdate({sleeveSlopeDivider: e}),
                            type: FormFieldType.text,
                            infoDetails: <div>A more horizontal sleeve would be set to 4 or 5, whereas a downwards sleeve would be 2.5 or 2</div>
                        },
                        {
                            value: this.state.sleeveSlack,
                            label: "Sleeve Slack",
                            suffix: "cm",
                            updateFunction: (e) => this.handleFormUpdate({sleeveSlack: e}),
                            type: FormFieldType.text
                        },
                        {
                            value: this.state.sleeveCurveRatio,
                            label: "Sleeve Curve Ratio",
                            updateFunction: (e) => this.handleFormUpdate({sleeveCurveRatio: e}),
                            type: FormFieldType.text,
                            infoDetails: <div>The ratio between the offset height of the curve on the sleeve. Should be higher than 1</div>
                        }
                    ]
                }/>
                <PresentationForm onChangeCallback={() => this.forceUpdate()}/>
                <PrintForm/>
                <ShapeRenderer sketch={frontBodySketch} name={"Front Body"} canvasWidth={canvasWidth} canvasHeight={frontBodySketch.sketchHeight + CANVAS_PADDING}/>
                <ShapeRenderer sketch={backBodySketch} name={"Back Body"} canvasWidth={canvasWidth} canvasHeight={backBodySketch.sketchHeight + CANVAS_PADDING}/>
                <ShapeRenderer sketch={shortSleeveSketch} name={"Short Sleeve"} canvasWidth={canvasWidth} canvasHeight={shortSleeveSketch.sketchHeight + CANVAS_PADDING}/>
                <ShapeRenderer sketch={longSleeveSketch} name={"Long Sleeve"} canvasWidth={canvasWidth} canvasHeight={longSleeveSketch.sketchHeight + CANVAS_PADDING}/>
            </div>)
            ;
    }
}