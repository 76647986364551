
export const SHAPE_KEY_CIRCLE = "circle";
export const SHAPE_KEY_RECTANGLE = "rectangle";
export const SHAPE_KEY_LINE = "line";
export const SHAPE_KEY_CURVE = "curve";
export const SHAPE_KEY_TEXT = "text";


export interface Coordinate {
    x: number,
    y: number
}

export interface SketchShape extends Coordinate{
    type: string,
    rotation: number
}

export interface SketchCircle extends SketchShape {
    radius: number
}

export interface SketchRectangle extends SketchShape {
    width: number,
    height: number
}


export enum LineStyle {
    SOLID,
    SEAM,
    GUIDE,
}
export interface SketchLine extends SketchShape {
    length: number,
    style: LineStyle,
}

export enum TextPlacement {
    BottomRight,
    BottomLeft,
    TopRight,
    TopLeft
}
export interface SketchText extends SketchShape {
    text: string,
    textAnchor: string,
    textPlacement: TextPlacement,
    sourceCoordinates: Coordinate
}

export interface SketchCurve extends SketchShape {
    path: string,
    style: LineStyle,
    rawPoints: CurveRawPoints
}

export interface CurveRawPoints {
    startPoint: Coordinate
    endPoint: Coordinate
    controlPoint1: Coordinate
    controlPoint2?: Coordinate
    controlPoint3?: Coordinate
}

export interface SketchResult {
    points: Map<number, Coordinate>,
    shapes: SketchShape[],
    sketchHeight: number,
    error?: string,
}

