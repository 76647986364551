export interface PrintSettings {
    pageSize: string,
    pageOrientation: PageOrientation,
    margins: MarginSettings,
}

export  interface MarginSettings {
    top: number,
    bottom: number,
    left: number,
    right: number
}


export interface PageSizeEntryMap {
    [key: string] : PaperDimensions
}

export interface PaperDimensions {
    mm: number[],
    points: number[],
    inches: number[]
}


export enum PageOrientation {
    Portrait= "Portrait",
    Landscape = "Landscape"
}

export interface PresentationDetails {
    showGuideLines: boolean,
    showPointNumbers: boolean,
    mirrorContent: boolean,
}

export interface TshirtMeasurements {
    seamAllowance: number,
    chest: number,
    armscyeHeight: number,
    neckToWaist: number,
    shirtLength: number,
    neck: number,
    halfBack: number,
    longSleeveLength: number,
    shortSleeveLength: number,
    bicep: number,
    wrist: number,
    backOfNeckCurve: number,
    shoulderTopSlopeAdjustment: number,
    shoulderFrontSlopeAdjustment: number,
    armscyeAdjustmentFrontVsBack: number,
    sleeveSlopeDivider: number,
    sleeveSlack: number,
    sleeveCurveRatio: number,
}

export interface PantsMeasurement {
    seamAllowance: number,
    waistband: number,
    seat: number,
    bodyRise: number,
    insideLeg: number,
    bottomHem: number,
    waistbandHeight: number,
    kneeHeight: number,
    seatPointRatio: number,
    legCurveSlope: number,
    kneePadding: number,
    kneeLength: number,
    buttAdjustmentRatio: number,
    backButtCurveAdjustment: number,
}